import { DataSource } from '@angular/cdk/table';
import { Userexternal } from './userexternal';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PagingAttributes, PaginationPage, Pagination } from 'app/shared/pagination';
import { UserexternalService } from '../userexternal.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

export class UserexternalDs implements DataSource<Userexternal> {
  public pagination = new BehaviorSubject<PagingAttributes>(
    new PagingAttributes()
  );

  public subject = new BehaviorSubject<Userexternal[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private service: UserexternalService) { }

  connect(collectionViewer: CollectionViewer): Observable<Userexternal[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  loadData(page: PaginationPage): void {
    this.loadingSubject.next(true);
    this.service.loadData(page).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((data: Pagination<Userexternal>) => {
        this.subject.next(data.aData);
        this.pagination.next({
          iTotalDisplayRecords: data.iTotalDisplayRecords,
          iTotalRecords: data.iTotalRecords
        });
      });
  }
}
