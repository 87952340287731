import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from 'app/shared/storage.service';
import { PaginationPage, Pagination } from 'app/shared/pagination';
import { Observable, of } from 'rxjs';
import { MyTraining, PracticalTest, Project } from './model/my-training';
import { ApiResponse } from 'app/shared/api-response';
import { map, catchError } from 'rxjs/operators';
import { Profiles } from 'app/shared/auth/auth';
import { ModulesPoolPracticePoint } from '../modules-pool/model/modules-pool';

@Injectable({
  providedIn: 'root'
})
export class EmpmodulepoolService {

  private appSettings = new AppSettings();
  company: string;
  apiEndpoint: string;

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {
    this.company = this.storage.get('empSite');
    this.apiEndpoint = this.appSettings.getApiEndpoint('empModulePool');
  }

  loadData(page: PaginationPage): Observable<Pagination<MyTraining>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('action', 'viewData');
    Object.keys(page).forEach(item => {
      // if (item === 'company') {
      //   httpParams = httpParams.append(item, this.company);
      // } else {
      httpParams = httpParams.append(item, page[item]);
      // }
    });

    return this.http.get<Pagination<MyTraining>>(this.apiEndpoint, {
      params: httpParams
    });
  }

  loadDataMyTraining(page: PaginationPage): Observable<Pagination<MyTraining>> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('action', 'viewDataMyTraining');
    Object.keys(page).forEach(item => {
      // if (item === 'company') {
      //   httpParams = httpParams.append(item, this.company);
      // } else {
      httpParams = httpParams.append(item, page[item]);
      // }
    });

    return this.http.get<Pagination<MyTraining>>(this.apiEndpoint, {
      params: httpParams
    });
  }

  deleteData(params: any): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(this.apiEndpoint, {
        action: 'deleteModules',
        data: params
      })
      .pipe(
        map(result => result),
        catchError(errors => of(errors.error))
      );
  }

  createData(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: "create",
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  updateData(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'update',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  searchDataEmployee(search: string): Observable<Profiles[]> {
    const httpParams = new HttpParams()
      .set('action', 'getDataEmployee')
      .set('search', search);
    return this.http
      .get<Profiles[]>(this.apiEndpoint, {
        params: httpParams
      })
      .pipe(
        map(success => success),
        catchError(errors => of(errors.error))
      );
  }

  loadPoolList(id: string): Observable<any> {
    return this.http.get<any>(this.apiEndpoint, {
      params: {
        action: 'getDetail',
        emp_modules_pool_id: id
      }
    });
  }

  loadPracticalTest(id: any): Observable<PracticalTest[]> {
    const httpParams = new HttpParams()
      .set('action', 'viewPracticalTest')
      .set('id', id);

    return this.http.get<PracticalTest[]>(this.apiEndpoint, {
      params: httpParams
    });
  }

  updateCrewCheck(data: PracticalTest[]): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'updateCrewCheck',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  updateCrewScore(data: PracticalTest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'updateCrewScore',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  getCrewScoreParam(id: string): Observable<ModulesPoolPracticePoint[]> {
    return this.http.get<ModulesPoolPracticePoint[]>(this.apiEndpoint, {
      params: {
        action: 'getCrewScoreParam',
        emp_modules_pool_id: id
      }
    });
  }

  loadProject(id: string): Observable<any> {
    return this.http.get<any>(this.apiEndpoint, {
      params: {
        action: 'getProject',
        emp_modules_pool_id: id
      }
    });
  }

  submitProject(data: Project): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'submitProject',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  // uploadFile(url: string, formData: FormData): Observable<any> {
  //   // let headers = new HttpHeaders();
  //   // headers = headers.set('Content-Type', 'multipart/form-data');
  //   return this.http.post<ApiResponse>(url, formData)
  //     .pipe(
  //       map(response => response),
  //       catchError(error => of(error.error))
  //     );
  // }

  uploadFile(url: string, data: any): Observable<any> {
    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  post(url: string, data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  loadDataCertificate(): Observable<any> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('action', 'getDataCertificate');

    return this.http.get<Pagination<MyTraining>>(this.apiEndpoint, {
      params: httpParams
    });
  }

  loadProjectTest(id: any): Observable<PracticalTest[]> {
    const httpParams = new HttpParams()
      .set('action', 'viewProjectTest')
      .set('id', id);

    return this.http.get<PracticalTest[]>(this.apiEndpoint, {
      params: httpParams
    });
  }

  updateProjectTest(data: PracticalTest[]): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'updateProjectTest',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  submitDataCertificate(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'submitDataCertificate',
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  retestPost(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: "retestPost",
      data: data
    })
      .pipe(
        map(response => response),
        catchError(error => of(error.error))
      );
  }

  getFile(url: string, params?: object): Observable<Blob> {
    // let headers = new HttpHeaders({
    //     'Cache-Control':
    //         'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    //     Pragma: 'no-cache',
    //     Expires: '0',
    // });
    let httpParams = new HttpParams();

    if (typeof params === 'object') {
        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'object' ) {
                Object.keys(params[key]).forEach((key_child) => {
                    if (typeof params[key][key_child] !== 'undefined') {
                        httpParams = httpParams.append(key_child, params[key][key_child]);
                    }
                });
            } else if (typeof params[key] !== 'undefined') {
                httpParams = httpParams.append(key, params[key]);
            }
        });
    }
    return this.http.get(url, {
        params: httpParams,
        // headers,
        responseType: 'blob'
    })
  }

  loadHistoryPoolList(nik: string): Observable<any> {
    return this.http.get<any>(this.apiEndpoint, {
      params: {
        action: 'getHistoryPoolList',
        nik: nik
      }
    });
  }
}
