import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PracticalTest } from 'app/main/empmodulepool/model/empmodulepool';
import { ModulesService } from 'app/main/modules/modules.service';
import { EmpmodulepoolService } from 'app/main/empmodulepool/empmodulepool.service';
import { fuseAnimations } from '@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { AppSettings } from 'app/app.settings';
import { ModulespoolService } from 'app/main/modulespool/modulespool.service';
import {IStarRatingOnClickEvent, IStarRatingOnRatingChangeEven} from "angular-star-rating/src/star-rating-struct";
 
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  animations: fuseAnimations,
})
export class RatingComponent implements OnInit {
  toggleDelete = false;
  selection = new SelectionModel<PracticalTest>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  ProjectList: any[];
  displayedColumns = ["select", "no", "name", "periode", "isCrewCheck", "dateChecked", "score"];
  apiEndpoint: string;
  private appSettings = new AppSettings();
  data_emp: any;

  onClickResult:IStarRatingOnClickEvent;
    onRatingChangeResult:IStarRatingOnRatingChangeEven;
  constructor(
    public dialogRef: MatDialogRef<PracticalTest>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceEmpModulesPool: EmpmodulepoolService,
    public commonDialogService: CommonDialogService,
    public snackBar: MatSnackBar,
    private service: EmpmodulepoolService,
  ) {
    this.data_emp = this.data ? this.data : null;

  }

  ngOnInit() {
  }

  onClick = ($event:IStarRatingOnClickEvent) => {
    console.log('onClick $event: ', $event);
    this.onClickResult = $event;
};

onRatingChange = ($event:IStarRatingOnRatingChangeEven) => {
    console.log('onRatingUpdated $event: ', $event);
    this.onRatingChangeResult = $event;
};

  submitCheck(): void {
    const dialog = new CommonDialog({
      title: "Project Test",
      message: "Are you sure want to submit this item ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        this.serviceEmpModulesPool.updateProjectTest(this.ProjectList).subscribe(val => {
          // this.loadData();
          this.dialogRef.close();
          this.snackBar.open(val.message, "OK", {
            duration: 90000,
            horizontalPosition: "end",
            verticalPosition: "top",
            panelClass: ["snackbar-info"]
          });
        });
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }

  uploadFile(event, index) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.apiEndpoint = this.appSettings.getApiEndpoint('empModulePool');

      const el = file;
      const formData = new FormData();
      // formData.append('id', 'test');
      formData.append('files', file, file.name);

      this.service.uploadFile(this.apiEndpoint + '/uploadFileProject', formData).subscribe(response => {
        // this.ProjectList[index].path_file_result=response.file_name;
      });

    }
  }
}
