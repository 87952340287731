import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { StorageService } from 'app/shared/storage.service';
import { catchError, flatMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router, 
        private authService: AuthService,
        private storageService: StorageService,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.params.get('action') === 'logout' ||
            request.params.get('action') === 'login'
        ) {
            return next.handle(request);
        } else {
            return next.handle(this.updateHeader(request)).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            console.log(error.status);
                            return this.authService.refreshToken().pipe(
                                flatMap((data) => {
                                    if (data.token) {
                                        this.authService.setToken(data.token, '');
                                        const decodeToken = this.authService.decodeToken(data.token);
                                        this.storageService.set('tokenID', decodeToken.tokenID);
                                    } else {
                                        this.authService.logout();
                                        this.router.navigate(['login']);
                                    }
                                    return next.handle(
                                        this.updateHeader(request)
                                    );
                                })
                            );
                        } else {
                            if (error.url.endsWith('refreshToken')) {
                                this.authService.logout();
                                this.router.navigate(['login']);
                            }
                            return throwError(error);
                        }
                    }
                    return throwError(error);
                })
            );
        }
    }

    private updateHeader(request: HttpRequest<any>): HttpRequest<any> {
        const authToken = this.authService.getToken();
        if(authToken){
            request = request.clone({
                headers: request.headers.set(
                    'Authorization',
                    `Bearer ${authToken}`
                ),
            });
        }
        
        // request = request.clone({
        //     headers: request.headers.set('Content-Type', 'application/json'),
        // });
        return request;
    }
}
