import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PracticalTest } from '../../model/empmodulepool';
import { EmpmodulepoolService } from '../../empmodulepool.service';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { ModulesPoolPracticePoint } from 'app/main/modules-pool/model/modules-pool';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-practice-score',
  templateUrl: './update-practice-score.component.html',
  styleUrls: ['./update-practice-score.component.scss']
})
export class UpdatePracticeScoreComponent implements OnInit {
  form: FormGroup;
  isLoading = false;
  unsubscribeAll = new Subject();
  scoreList: ModulesPoolPracticePoint[] = [];

  constructor(
    public dialogRef: MatDialogRef<PracticalTest>,
    @Inject(MAT_DIALOG_DATA) public data: PracticalTest,
    private service: EmpmodulepoolService,
    public commonDialogService : CommonDialogService,
    private formBuilder: FormBuilder,
    public snackBar : MatSnackBar
  ) { }

  ngOnInit(): void{
    this.service.getCrewScoreParam(this.data.el_module_pool_id.toString()).subscribe(response => {
      this.scoreList = response;
    });

    this.form = this.formBuilder.group({
      id : new FormControl(this.data.id),
      score : new FormControl(this.data.score, Validators.required),
      score_id : new FormControl(this.data.score_id)
    });
  }

  submitCheck():void{
    this.service.updateCrewScore(this.form.value).subscribe(val => {
      this.snackBar.open(val.message, "OK", {
        duration: 90000,
        horizontalPosition: "end",
        verticalPosition: "top",
        panelClass: ["snackbar-info"]
      });
      
    });
  }

}
