import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from 'app/shared/storage.service';
import { AccountsPage } from 'app/account/model/account';
import { Observable, of } from 'rxjs';
import { Pagination } from 'app/shared/pagination';
import { Modulespool } from './model/modulespool';
import { ApiResponse } from 'app/shared/api-response';
import { map, catchError } from 'rxjs/operators';
import { Profiles } from 'app/shared/auth/auth';

@Injectable({
  providedIn: 'root'
})
export class ModulespoolService {
  private appSettings = new AppSettings();
  company: string;
  apiEndpoint: string;
  
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { 
    this.company = this.storage.get('empSite');
    this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
  }

  
  loadData(page: AccountsPage):Observable<Pagination<Modulespool>>{
    let httpParams = new HttpParams();

    httpParams = httpParams.append('action', 'modulesPool');
    Object.keys(page).forEach(item => {
      if (item === 'company') {
        httpParams = httpParams.append(item, this.company);
      } else {
        httpParams = httpParams.append(item, page[item]);
      }
    });

    return this.http.get<Pagination<Modulespool>>(this.apiEndpoint,{
      params: httpParams
    });
  }
  
  deleteData(params: any):Observable<ApiResponse>{
    return this.http
      .post<ApiResponse>(this.apiEndpoint, {
        action: 'deleteModulesPool',
        data: params
      })
      .pipe(
        map(result => result),
        catchError(errors => of(errors.error))
        );
  }

  createData(modulestype: any): Observable<ApiResponse>{
    return this.http.post<ApiResponse>(this.apiEndpoint,{
      action: "createModulesPool",
      data: modulestype
    })
    .pipe(
      map(response => response),
      catchError(error => of(error.error))
    );
  }

  updateData(modulestype: any): Observable<ApiResponse>{
    return this.http.post<ApiResponse>(this.apiEndpoint, {
      action: 'updateModulesPool',
      data: modulestype
    })
    .pipe(
        map(response => response),
        catchError(error => of(error.error))
    );
  }

  searchDataModulesPool(search: string): Observable<Modulespool[]> {
    const httpParams = new HttpParams()
        .set('action', 'getDataModulesPool')
        .set('search', search);
    return this.http
        .get<Modulespool[]>(this.apiEndpoint, {
            params: httpParams
        })
        .pipe(
            map(success => success),
            catchError(errors => of(errors.error))
        );
  }

}
