import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthService } from 'app/shared/auth/auth.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatSnackBar, MatButton } from '@angular/material';
import { StorageService } from 'app/shared/storage.service';
import { MenuService } from 'app/shared/menu.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
    */
    constructor(
        private fuseConfigService: FuseConfigService,
        private formBuilder: FormBuilder,
        private splasScreen: FuseSplashScreenService,
        private authService: AuthService,
        private router: Router,
        public snackBar: MatSnackBar,
        private storageService: StorageService,
        private menuService : MenuService
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.storageService.clear();
        this.authService.setCookies();
    }

    login(): void {
        const username = this.loginForm.get('username').value;
        const password = this.loginForm.get('password').value;
        const request = this.authService.login(username, password);

        request.subscribe(
            data => {                
                if (data && data.token) {
                    this.splasScreen.show();
                    this.authService.setToken(data.token, data.menus);
                    // this.menuService.listMenu.next(JSON.parse(data.menus));
                    this.menuService.listMenu.next(data.menus);
                    setTimeout(() => {
                        this.router.navigate(['dashboardpool']);
                    }, 2000);
                }
            },
            error => {
                this.openSnackBar(error.error.message, 'close');
            }
        );
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 4000,
            panelClass: ['snackbar-failed']
        });
    }

    ngOnDestroy(): void {
        // setTimeout(() => {
            this.splasScreen.hide();
        // }, 3500);
    }

}
