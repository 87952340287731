import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'NAV.DASHBOARD.TITLE',
        type: 'item',
        icon: 'dashboard',
        url: 'dashboardpool',
    },
    {
        id: 'modules',
        title: 'Modules',
        translate: 'NAV.MODULES',
        type: 'group',
        icon: 'collections_bookmark',
        children: [
            {
                id: 'modulesType',
                title: 'Modules Type',
                translate: 'NAV.MODULESTYPE.TITLE',
                type: 'item',
                icon: 'bookmark_border',
                url: 'modulestype',
            },
            {
                id: 'modules',
                title: 'Modules',
                translate: 'NAV.ELMODULES.TITLE',
                type: 'item',
                icon: 'book',
                url: 'modules',
            },
            {
                id: 'modulesPool',
                title: 'Modules Pool',
                translate: 'NAV.EMP_MODULES_POOL.TITLE',
                type: 'item',
                icon: 'collections_bookmark',
                url: 'modules-pool/view',
            },
        ],
    },
    // {
    //     id: 'empmodulepool',
    //     title: 'Emp Modules Pool',
    //     translate: 'NAV.EMP_MODULES_POOL.TITLE',
    //     type: 'item',
    //     icon: 'school',
    //     url: 'empmodulepool',
    // },
    // {
    //     id: 'trainingcalendar',
    //     title: 'Training Calendar',
    //     translate: 'NAV.TRAINING_CALENDAR.TITLE',
    //     type: 'item',
    //     icon: 'event',
    //     url: 'calendar',
    // },
    // {
    //     id: 'dashboardadmin',
    //     title: 'Dashboard Admin',
    //     translate: 'NAV.DASHBOARD_ADMIN.TITLE',
    //     type: 'item',
    //     icon: 'dashboard',
    //     url: 'dashboard-admin',
    // },
];
