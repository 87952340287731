import { Modules } from "app/main/modules/model/modules";
import { OnlineTestPool } from "app/shared/interfaces/online-test-pool";

export interface ModulesPool {
    id: number;
    pool_name: string;
    description: string;
    bu: string;
    country: string;
    pool_type: string;
    action_by: string;
    post_parameter: number;
    post_duration: number;
    pre_duration: number;
    pre_test_pool: OnlineTestPool;
    post_test_pool: OnlineTestPool;
    is_practice_required: any;
    is_project_required: any;
    is_restriction: any;
    practices_pool?: ModulesPoolPracticeTest[];
    practice_point_param?: ModulesPoolPracticePoint[];
}

export class ModulesPoolPracticeTest {
    id?: any;
    item_name: string;
    el_module_competencies_id: any;

    constructor(itemName: string, elModuleCompetenciesId: any, id?: any) {
        this.item_name = itemName;
        this.id = id;
        this.el_module_competencies_id = elModuleCompetenciesId;
    }
}

export class ModulesPoolPracticePoint {
    id?: any;
    description: string;
    point: any;
    el_module_pool_id?: any;

    constructor(description: string, point: any, id?: any, elModulePoolId?: any) {
        this.description = description;
        this.id = id;
        this.el_module_pool_id = elModulePoolId;
        this.point = point;
    }
}

export class ModulesPoolList {
    id?: number;
    modules_pool?: ModulesPool;
    modules: Modules;
    order_number?: any;
    is_mandatory?: any;
    list_order?: any;
    action_by?: string;
    selected?: string;

    constructor(modulePoolList: ModulesPoolList) {
        Object.keys(modulePoolList).forEach(key => {
            this[key] = modulePoolList[key];
        });
    }
}

export const POOL_TYPES = ["PIP", "IDP", "ON-BOARDING", "GENERAL"];

export class ModulesPoolPage {
    limit = 20;
    offset = 0;
    search? = "";
    country? = "";
    company? = "";
    subArea? = "";
}

export interface ModulesSearch {
    id: number;
    module_title: string;
    description: string;
    modules_type: ModulesType;
    question_pool: OnlineTestPool;
    is_need_assesment: number | string;
    assesment_parameter: number | string;
    assesment_duration: number;
}

export interface ModulesType {
    id: number;
    description: string;
}
