export class Helper {
  public buildDeletePayload(arrObj: Array<any>): Array<any> {
    const response = new Array();

    if (typeof arrObj === 'undefined') {
        return null;
    }

    arrObj.forEach(val => {
        if ('id' in val) {
            response.push(val.id);
        } else {
            return true;
        }
    });

    return response;
}
}
