import { NgModule } from "@angular/core";
import { ModulesPoolComponent } from "./modules-pool.component";
import { AuthGuard } from "app/shared/auth/auth.guard";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatSelectFilterModule } from 'mat-select-filter';

import {
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule
} from "@angular/material";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormModulesPoolComponent } from "./form-modules-pool/form-modules-pool.component";
import { TransactionComponent } from "./transaction/transaction.component";
import { ViewComponent } from "./view/view.component";
import { TransactionResolverService } from "./transaction/transaction-resolver.service";

const routes: Routes = [
    {
        path: "modules-pool",
        component: ModulesPoolComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "view",
                component: ViewComponent
            },
            {
                path: "transaction",
                component: TransactionComponent
            },
            {
                path: "transaction/:id",
                component: TransactionComponent,
                resolve: {
                    detail: TransactionResolverService
                }
            }
        ]
    }
];

@NgModule({
    declarations: [
        ModulesPoolComponent,
        FormModulesPoolComponent,
        TransactionComponent,
        ViewComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MatProgressBarModule,
        MatInputModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatRadioModule,
        DragDropModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSelectFilterModule,
        MatDatepickerModule
    ],
    exports: [RouterModule],
    entryComponents: [FormModulesPoolComponent]
})
export class ModulesPoolModule {}
