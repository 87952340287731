import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from '@angular/forms';
import {
    MatAutocompleteSelectedEvent,
    MatRadioChange,
    MatSelectChange,
    MatSnackBar,
} from '@angular/material';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import {
    takeUntil,
    debounceTime,
    distinctUntilChanged,
    tap,
    switchMap,
    finalize,
} from 'rxjs/operators';
import {
    POOL_TYPES,
    ModulesPoolPracticeTest,
    ModulesSearch,
    ModulesPoolList,
    ModulesPoolPracticePoint
} from "../model/modules-pool";
import { OnlineTestPool } from "app/shared/interfaces/online-test-pool";
import { ModulesPoolService } from "../modules-pool.service";
import { References, MainCategory, Category, MainActivity, Activity, SkillActivity, ReferenceType, CompetencyPool, SkillIndicatorPool } from "app/shared/interfaces/references";
import { StorageService } from "app/shared/storage.service";
import { CommonDialog } from "app/shared/component/dialog/common-dialog/common-dialog";
import { fuseAnimations } from "@fuse/animations";
import { ModulesPoolComponent } from "../modules-pool.component";
import { Modules } from "app/main/modules/model/modules";
import { EmpmodulepoolService } from '../../empmodulepool/empmodulepool.service';

import { CommonDialogService } from "app/shared/component/dialog/common-dialog/common-dialog.service";
import { ApiResponse } from "app/shared/api-response";
import * as _ from 'lodash'
import { Empmodulepool, Project } from '../../empmodulepool/model/empmodulepool';
import { AppSettings } from 'app/app.settings';

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss'],
    animations: [fuseAnimations],
})
export class TransactionComponent implements OnInit, OnDestroy {
    form: FormGroup;
    categories: any = [];
    isEdit = false;
    unsubscribeAll = new Subject();
    isLoading = true;
    postTestPool: OnlineTestPool[] = [];
    preTestPool: OnlineTestPool[] = [];
    listCompany: References[];
    listMainCategory: MainCategory[];
    company: string;
    poolTypes = POOL_TYPES;
    filteredPostPool: OnlineTestPool[] = [];
    filteredPrePool: OnlineTestPool[] = [];
    filteredModules: ModulesSearch[] = [];
    isLoadingPost = false;
    isLoadingPre = false;
    isLoadingModules = false;
    practicePointParam: ModulesPoolPracticePoint[] = [];
    showPracticeList = false;
    practiceItemName = '';
    errorMsg = '';
    modulesSearch: ModulesSearch[] = [];
    modulesPoolList: ModulesPoolList[] = [];
    modulesPoolListClone: ModulesPoolList[] = [];
    selectedPoolListClone: Modules;
    initializer = new BehaviorSubject<boolean>(false);
    isFinishSetup = false;
    pointParam = 0;
    descriptionParam = '';
    showPreTest = false;
    showPostTest = false;
    showSkillMatrix = false;
    showCompetencies = false;
    showProject = false;
    listCategory: Category[];
    listMainActivity: MainActivity[];
    listActivity: Activity[];
    listSkillActivity: SkillActivity[];
    listType: ReferenceType[];
    listCompetencyCategory: References[];
    listCompetencies: References[];

    listSkillIndicator: SkillIndicatorPool[];
    skillIndicatorPool: SkillIndicatorPool[] = [];
    mainCategory: number;
    category: number;
    mainActivity: number;
    activity: number;
    skillActivity: number;
    skillIndicator: number;

    competencyPool: CompetencyPool[] = [];
    categoryCompetency: number;
    competency: number;
    startLevel: number;
    targetLevel: number;
    listSubarea: any[];
    filteredSubarea: any[];

    practicesPool: ModulesPoolPracticeTest[] = [];

    listDataSkillActivity: any[] = [];

    listDataCertificate: any[] = [];

    projectTitle = null;
    durationProject = null;
    endDateProject = null;
    targetProject = null;
    projectItem: Project[] = [];

    private appSettings = new AppSettings();
    apiEndpoint: string;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private modulesPoolService: ModulesPoolService,
        private storageService: StorageService,
        @Inject(ModulesPoolComponent) private parent: ModulesPoolComponent,
        private snackbar: MatSnackBar,
        private commonDialogService: CommonDialogService,
        private service: EmpmodulepoolService,
    ) {
        /**
         * TODO
         *
         * Get translation
         */
        this.parent.titleSubject.next('Create Module Pool');
        this.parent.descriptionSubject.next(
            'Setup Module Pool & Module Pool List'
        );

        this.company = this.storageService.get('empSite');
    }

    ngOnInit() {
        this.loadDataCertificate();

        this.parent.blockComponent.next(true);

        this.initializer.subscribe((isFinish) => {
            if (isFinish) {
                this.isFinishSetup = true;
                this.initFilter();
                this.initAutoComplete('pre_test_pool', 'PRE');
                this.initAutoComplete('post_test_pool', 'POST');
                this.initAutoComplete('modules_search', 'MODULES');
                this.parent.blockComponent.next(false);
            }
        });
        this.route.data.subscribe((data) => {
            if (!this.isEmptyObject(data)) {
                console.log('not empty : ', data);
                this.initUpdate(data.detail);
            } else {
                console.log('empty : ', data);
                this.initCreate();
            }
        });
    }

    initUpdate(detail: any): void {
        this.isEdit = true;

        if (detail.pre_test_pool.id > 0) {
            this.showPreTest = true;
        }

        if (detail.post_test_pool.id > 0) {
            this.showPostTest = true;
        }

        if (detail.is_project_required == '1') {
            this.showProject = true;
        }

        this.getSubArea(detail.bu);

        this.form = this.formBuilder.group({
            type: new FormControl(detail.type, Validators.required),
            id: new FormControl(detail.id),
            pool_name: new FormControl(detail.pool_name, Validators.required),
            description: new FormControl(
                detail.description,
                Validators.required
            ),
            bu: new FormControl(detail.bu, Validators.required),
            pool_type: new FormControl(detail.pool_type, Validators.required),
            post_parameter: new FormControl(
                detail.post_parameter,
                Validators.required
            ),
            post_duration: new FormControl(
                detail.post_duration,
                Validators.maxLength(180)
            ),
            pre_duration: new FormControl(
                detail.pre_duration,
                Validators.maxLength(180)
            ),
            pre_test_pool: new FormControl(detail.pre_test_pool),
            post_test_pool: new FormControl(detail.post_test_pool),
            is_project_required: new FormControl(
                (detail.is_project_required == '1') ? true : false,
                Validators.required
            ),
            is_restriction: new FormControl(
                (detail.is_restriction == '1') ? true : false,
                Validators.required
            ),
            modules_search: new FormControl(''),
            is_autocheck_practice: new FormControl(
                detail.is_autocheck_practice,
                Validators.required
            ),
            is_pre_test_required: new FormControl(this.showPreTest),
            is_post_test_required: new FormControl(this.showPostTest),
            learning_type: new FormControl(detail.learning_type),
            sub_area: new FormControl(detail.sub_area, Validators.required),
            learning_duration: new FormControl(detail.learning_duration, Validators.required),
            certificate_configurations_id: new FormControl(detail.certificate_configurations_id),
            background: new FormControl(
                detail.background,
                Validators.required
            ),
        });
        this.setLearningType(detail.learning_type);

        this.competencyPool = detail.competencyPool;
        this.showPracticeList = this.competencyPool.length > 0;

        this.modulesPoolList = detail.modules_pool_list;
        this.initializer.next(true);
        this.initializer.complete();
        this.practicePointParam = detail.practice_point_param;
        this.skillIndicatorPool = detail.skillIndicatorPool;

        for (let i = 0; i < detail.project_list.length; i++) {
            this.projectItem.push(
                new Project(detail.project_list[i].id, detail.project_list[i].project_title, detail.project_list[i].target, detail.project_list[i].duration)
            );

        }
    }

    initCreate(): void {
        console.log(this.company);
        this.getSubArea(this.company);
        this.form = this.formBuilder.group({
            type: new FormControl('limited', Validators.required),
            pool_name: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            bu: new FormControl(this.company, Validators.required),
            pool_type: new FormControl('', Validators.required),
            post_parameter: new FormControl(''),
            post_duration: new FormControl('', Validators.maxLength(180)),
            pre_duration: new FormControl('', Validators.maxLength(180)),
            pre_test_pool: new FormControl(''),
            post_test_pool: new FormControl(''),
            is_practice_required: new FormControl(false),
            is_project_required: new FormControl(false),
            is_restriction: new FormControl(false),
            modules_search: new FormControl(''),
            is_autocheck_practice: new FormControl(false, Validators.required),
            learning_type: new FormControl(0, Validators.required),
            is_pre_test_required: new FormControl(false),
            is_post_test_required: new FormControl(false),
            sub_area: new FormControl("", Validators.required),
            learning_duration: new FormControl(100, Validators.required),
            certificate_configurations_id: new FormControl(''),
            background: new FormControl("", Validators.required),
        });
        this.initializer.next(true);
        this.initializer.complete();
    }

    initFilter(): void {
        this.modulesPoolService.loadType().subscribe((val) => {
            this.listType = val;
        });

        this.modulesPoolService.loadCompany().subscribe((val) => {
            this.listCompany = val;
        });
    }

    displayFn(questionPool: OnlineTestPool): string {
        if (questionPool) {
            return questionPool.description;
        }
    }

    displayFnModules(modules: ModulesSearch): string {
        if (modules) {
            return modules.module_title;
        }
    }

    initAutoComplete(field: string, type: string): void {
        let company = this.form.get('bu').value;
        this.form
            .get(field)
            .valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => this.switchLoadingProgress(type, true)),
                switchMap((value) => {
                    if (type === 'MODULES') {
                        let currModules = '';
                        if (this.modulesPoolList.length > 0) {
                            currModules = this.modulesPoolList
                                .map((elem) => {
                                    return elem.modules.id;
                                })
                                .join(',');
                        }

                        return this.modulesPoolService
                            .searchModules(value, currModules)
                            .pipe(
                                takeUntil(this.unsubscribeAll),
                                finalize(() =>
                                    this.switchLoadingProgress(type, false)
                                )
                            );
                    }

                    return this.modulesPoolService
                        .loadQuestionPool(company, value)
                        .pipe(
                            takeUntil(this.unsubscribeAll),
                            finalize(() =>
                                this.switchLoadingProgress(type, false)
                            )
                        );
                })
            )
            .subscribe((data) => this.switchFilteredValue(type, data));
    }

    onSubmit(): void {
        this.parent.blockComponent.next(true);

        let submit: Observable<any>;
        let form = this.form.value;
        console.log(form);
        form.competency_pool = this.competencyPool;
        form.skill_matrix_pool = this.listDataSkillActivity;
        form.modules_pool_list = this.modulesPoolList;
        form.practice_point_param = this.practicePointParam;
        form.projectItem = this.projectItem;

        if (this.isEdit) {
            submit = this.modulesPoolService.updateData(form);
        } else {
            submit = this.modulesPoolService.createData(form);
        }

        submit.subscribe(
            (response) => {
                this.parent.blockComponent.next(false);
                this.responseDialog(response, true);
            },
            (error) => {
                this.parent.blockComponent.next(false);
                this.responseDialog(error, false);
            }
        );
    }

    onIsPracticeRequiredChange(): void {
        if (!this.form.value.is_autocheck_practice) {
            this.showPracticeList = true;
        } else {
            this.showPracticeList = false;
        }
    }

    onClickPracticeSubmit(competency: CompetencyPool, practice: any): void {
        this.practicesPool.push(
            new ModulesPoolPracticeTest(practice, competency.competency_id)
        );

        let item = this.practicesPool.filter(function (val) {
            return val.el_module_competencies_id == competency.competency_id;
        });

        competency.practicePool = item;
        this.practiceItemName = '';
    }

    onRemovePracticeItem(comptId: number, idx: number, text: string): void {
        console.log(this.practicesPool);

        let competency = this.competencyPool.filter(function (item) {
            return item.competency_id == comptId;
        });

        let inds = _.findIndex(this.practicesPool, {
            item_name: text,
            el_module_competencies_id: comptId,
        });

        competency[0].practicePool.splice(idx, 1);
        this.practicesPool.splice(inds, 1);
    }

    onSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        switch (type) {
            case 'PRE':
                this.form.get('pre_test_pool').setValue(event.option.value);
                break;
            case 'POST':
                this.form.get('post_test_pool').setValue(event.option.value);
                break;
            case 'MODULES':
                this.modulesPoolList.push(
                    new ModulesPoolList({
                        modules: event.option.value,
                        order_number: this.modulesPoolList.length,
                        list_order: [],
                        selected: undefined,
                        is_mandatory: false,
                    })
                );

                this.modulesPoolListClone.push(
                    new ModulesPoolList({
                        modules: event.option.value,
                        order_number: this.modulesPoolList.length,
                        selected: undefined,
                    })
                );
                this.form.get('modules_search').setValue('');
                break;
        }
    }

    switchLoadingProgress(type: string, value: any): void {
        switch (type) {
            case 'PRE':
                this.isLoadingPre = value;
                break;
            case 'POST':
                this.isLoadingPost = value;
                break;
            case 'MODULES':
                this.isLoadingModules = value;
                break;
        }
    }

    switchFilteredValue(type: string, value: any): void {
        switch (type) {
            case 'PRE':
                this.filteredPrePool = value;
                break;
            case 'POST':
                this.filteredPostPool = value;
                break;
            case 'MODULES':
                this.filteredModules = value;
                break;
        }
    }

    onRemovePoolList(idx: number) {
        this.modulesPoolList.splice(idx, 1);
    }

    onSelectedListOrder(
        event: MatSelectChange,
        currModulesId: any,
        container: Array<any>
    ) {
        if (!event.value) {
            return;
        }
        if (currModulesId == event.value.modules.id) {
            this.snackbar.open(
                'Cannot select same module to list order',
                'OK',
                {
                    duration: 1500,
                }
            );
            return;
        }
        container.push(event.value);
        const idx = this.modulesPoolListClone.findIndex(
            (x) => x.modules.id === event.value.modules.id
        );
        if (idx !== -1) {
            this.modulesPoolListClone.splice(idx, 1);
        }
    }

    onClickDeleteOrder(
        item: any,
        idx: number,
        container: Array<any>,
        selector: any
    ) {
        this.modulesPoolListClone.push(item);
        container.splice(idx, 1);
        console.log(selector);
        selector.selected = '';
        console.log(selector);
    }

    responseDialog(response: ApiResponse, isBack: boolean): void {
        const dialog = new CommonDialog({
            title: response.title,
            message: response.message,
        });
        this.commonDialogService
            .open(dialog)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((response) => {
                if (response.isSubmit() && isBack) {
                    this.parent.backToView();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    isEmptyObject(obj) {
        return obj && Object.keys(obj).length === 0;
    }

    onClickPracticePointSubmit(): void {
        this.practicePointParam.push(
            new ModulesPoolPracticePoint(this.descriptionParam, this.pointParam)
        );
        this.descriptionParam = '';
        this.pointParam = 0;
    }

    onRemovePracticePointItem(idx: number): void {
        this.practicePointParam.splice(idx, 1);
    }

    onIsPreTestRequiredChange(): void {
        if (!this.form.value.is_pre_test_required) {
            this.showPreTest = true;
        } else {
            this.showPreTest = false;
        }
    }

    setLearningType(id: string): void {
        if (id == '1') {
            this.showSkillMatrix = true;
            this.showCompetencies = false;

            this.modulesPoolService.loadMainCategory().subscribe((val) => {
                this.listMainCategory = val;
            });
        } else if (id == '2') {
            this.showSkillMatrix = false;
            this.showCompetencies = true;

            this.modulesPoolService
                .loadCompetencyCategory()
                .subscribe((val) => {
                    this.listCompetencyCategory = val;
                });
        } else if (id == '3') {
            this.showSkillMatrix = true;
            this.showCompetencies = true;

            this.modulesPoolService
                .loadCompetencyCategory()
                .subscribe((val) => {
                    this.listCompetencyCategory = val;
                });

            this.modulesPoolService.loadMainCategory().subscribe((val) => {
                this.listMainCategory = val;
            });
        } else {
            this.showSkillMatrix = false;
            this.showCompetencies = false;
        }
    }

    getCategory(id: string): void {
        this.modulesPoolService.loadCategory(id).subscribe((val) => {
            this.listCategory = val;
        });
    }

    getMainActivity(id: string): void {
        this.modulesPoolService.loadMainActivity(id).subscribe((val) => {
            this.listMainActivity = val;
        });
    }

    getActivity(id: string): void {
        this.modulesPoolService.loadActivity(id).subscribe((val) => {
            this.listActivity = val;
        });
    }

    getSkillActivity(id: string): void {
        this.modulesPoolService.loadSkillActivity(id).subscribe((val) => {
            this.listSkillActivity = val;
        });
    }

    getSkillIndicator(id: string): void {
        this.modulesPoolService.loadSkillIndicator(id).subscribe((val) => {
            this.listSkillIndicator = val;
        });
    }

    getCompetencies(id: string): void {
        this.modulesPoolService.loadCompetencies(id).subscribe((val) => {
            this.listCompetencies = val;
        });
    }

    onClickCompetencySubmit(): void {
        let comp: any = this.competency;
        let cpt: any = this.listCompetencies.filter(function (item) {
            return item.id == comp;
        });

        this.competencyPool.push(
            new CompetencyPool(
                this.startLevel,
                this.targetLevel,
                this.competency,
                cpt[0].description
            )
        );
    }

    onRemoveCompetencyItem(idx: number): void {
        this.competencyPool.splice(idx, 1);
    }

    onClickMatrixSkillSubmit(): void {
        let indic: any = this.skillIndicator;
        let skill: any = this.listSkillIndicator.filter(function (item) {
            return item.id == indic;
        });

        this.skillIndicatorPool.push(
            new SkillIndicatorPool(
                skill[0].description,
                skill[0].success_criteria,
                this.skillIndicator
            )
        );
    }

    onClickMatrixSkillSubmitAll(): void {
        for (let obj of this.listSkillIndicator) {
            let item = _.findIndex(this.skillIndicatorPool, [
                'skill_indicator_id',
                obj.id,
            ]);

            if (item === -1) {
                this.skillIndicatorPool.push(
                    new SkillIndicatorPool(
                        obj.description,
                        obj.success_criteria,
                        obj.id
                    )
                );
            }
        }
    }

    onRemoveMatrixSkillItem(idx: number): void {
        this.skillIndicatorPool.splice(idx, 1);
    }

    getSubArea(id: string): void {
        this.modulesPoolService.loadSubArea(id).subscribe((val) => {
            this.listSubarea = val;
            this.filteredSubarea = val.slice();
        });
    }

    onIsPostTestRequiredChange(): void {
        if (!this.form.value.is_post_test_required) {
            this.showPostTest = true;
        } else {
            this.showPostTest = false;
        }
    }

    addSkillActivity() {
        this.listDataSkillActivity.push({
            skillActivity: null,
            skillIndikator: [],
        });
    }

    getListDataSkillIndicator(id: string, index: number): void {
        this.modulesPoolService.loadSkillIndicator(id).subscribe((val) => {
            this.listDataSkillActivity[index].skillIndikator = val;
        });
    }

    deleteSkillActivity(index: number) {
        this.listDataSkillActivity.splice(index, 1);
    }

    loadDataCertificate() {
        this.service.loadDataCertificate().subscribe((response) => {
            this.listDataCertificate = response;
        });
    }

    onIsProjectRequiredChange(): void {
        if (!this.form.value.is_project_required) {
            this.showProject = true;
        } else {
            this.showProject = false;
        }
    }

    onClickProjectSubmit(): void {

        this.projectItem.push(
            new Project(0, this.projectTitle, this.targetProject, this.durationProject)
        );

        this.projectTitle = "";
        this.durationProject = "";
        this.targetProject = "";
    }

    onRemoveProjectItem(idx: number): void {
        this.projectItem.splice(idx, 1);
    }

    uploadFile(event) {
        this.parent.blockComponent.next(true);
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.apiEndpoint = this.appSettings.getApiEndpoint('files');

            const el = file;
            const formData = new FormData();
            // formData.append('id', 'test');
            formData.append('action', "uploadBackgroundModule");
            formData.append('files', file, file.name);

            let data = {
                action: "uploadBackgroundModule"
            }

            this.service.uploadFile(this.apiEndpoint, formData).subscribe(response => {
                this.form.get('background').setValue(response.data.filename);
                this.parent.blockComponent.next(false);
            });

        }
    }

}
