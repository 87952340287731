export class Dashboardpool {
    id: number;
    el_module_pool_id: number;
    module_title: string;
    background: any;
    start_date: string;
    end_date: string;
    bu: string;
    country: string;
    description: string;
    pool_type: string;
    is_practice_required: string;
    is_project_required: string;
    pre_status: string;
    post_status: string;
    pool_name: string;
    pre_test_id: number;
    pre_test_name: string;
    pre_duration: string;
    post_duration: string;
    post_test_id: number;
    post_test_name: string;
    post_parameter: string;
    pre_test: DashboardPoolTest;
    post_test: DashboardPoolTest;
    color: string;
}

export class Dashboard {
    id: number;
    module_title: string;
    description: string;
    module_type: string;
    is_need_assesment: string;
    list_order: string;
    timestamp: string;
    id_type: number;
    is_allow: string;
    status_check: string;
    date_check: string;
    el_module_pool_list_id: number;
    emp_module_pool_id: any;
    color: string;
}

class DashboardPoolTest {
    id: number;
    emp_module_pool_id: number;
    score: string;
    test_status: string;
    timestamp: string;
    invitation_code: string;
    pre_test_ref_pool_id: any;
    post_test_ref_pool_id: any;
}

export class InvitationCode {
    el_module_pool_id: number;
    code: string;
    emp_module_pool_id: number;
    type: string;
}
