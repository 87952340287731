import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-matrix',
  templateUrl: './skill-matrix.component.html',
  styleUrls: ['./skill-matrix.component.scss']
})
export class SkillMatrixComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
