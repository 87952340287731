import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from 'app/app.settings';
import { Observable } from 'rxjs';
import { TrainingCalendar } from './training-calendar.model';

@Injectable()
export class TrainingCalendarService {
    private appSettings = new AppSettings();
    apiEndpoint: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get events
     *
     * @returns {Observable<any>}
     */
    getEvents(year: string, month: string, company:string, subArea:string): Observable<TrainingCalendar[]> {
        const params = new HttpParams()
            .set('action', 'getCalendarData')
            .set('year', year)
            .set('company', company)
            .set('subArea', subArea)
            .set('month', month);

        return this._httpClient.get<TrainingCalendar[]>(this.apiEndpoint, {
            params: params,
        });
    }
}
