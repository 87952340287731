import { EmpmodulepoolModule } from '../empmodulepool.module';

export class Empmodulepool {
  id: number;
	nik: string;
	el_module_pool_id: string;
	start_date: string;
	end_date: string;
	emp_name: string;
	n1_name: string;
	n1_nik: string;
	job_grade: string;
	emp_grade: string;
	job_title: string;
	bt: string;
	effective_date: string;
	abreviation: string;
	emp_category: string;
	module_pool_description: string;
	pool_name: string;
	pool_type: string;
	bu: string;
	country: string;
	is_practice_required: string;
	project:Project;
	start_date_practice : string;
	end_date_practice : string;
	pre_test: ModulesTest;
	post_test: ModulesTest;
	learning_type:string;
	is_project_required: string;
	progress:number;
}

export class PoolList {
  is_mandatory: string;
  module_title: number;
  module_type: number;
  is_need_assesment: string;
  assesment_parameter: string;
  assesment_duration: string;
  score: string;
  assesment_status: string;
  
}

export class Project {
	id:number;
	project_title: string;
	target: string;
	start_date: string;
	end_date: string;
	baseline: string;
	improvement:string;
	result:string;
	background:string;
	duration:string;

	constructor(id: number,projectTitle: string, target: string, duration: string) {
		this.id = id;
		this.project_title = projectTitle;
		this.target = target;
		this.duration = duration;
	}
}

export class PracticalTest{
	item_name: string;
	start_date: string;
	end_date: string;
	is_crew_check: string;
	date_checked: string;
	score: number;
	scored_by: string;
	scored_date: string;
	score_id: number;
	id: number;
	el_module_pool_id:number;
}

interface ModulesTest{
	id:number;
	score:string;
	test_status:number;
	type:string;
}

