import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import {
    CalendarEventAction,
    CalendarEvent,
    CalendarMonthViewDay,
    CalendarEventTimesChangedEvent,
} from 'angular-calendar';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Subject, Observable } from 'rxjs';

import {
    isSameMonth,
    isSameDay,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    format,
} from 'date-fns';
import { MyTrainingCalendarService } from './my-training-calendar.service';
import { MyTrainingCalendar } from './my-training-calendar.model';
import { finalize, map } from 'rxjs/operators';
import { References } from 'app/shared/interfaces/references';
import { ModulesPoolService } from '../modules-pool/modules-pool.service';
import { StorageService } from 'app/shared/storage.service';

@Component({
    selector: 'app-my-training-calendar',
    templateUrl: './my-training-calendar.component.html',
    styleUrls: ['./my-training-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MyTrainingCalendarComponent implements OnInit {
    actions: CalendarEventAction[];
    activeDayIsOpen: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;
    events: Observable<CalendarEvent[]>;
    refresh: Subject<any> = new Subject();
    selectedDay: any;
    view: string;
    viewDate: Date;
    company:string = this.storage.get('empSite');
    subArea:string = 'all';
    loadingState = {
        company: false,
        subArea: false,
    };
    companies: Observable<References[]>;
    subAreas: Observable<References[]>;

    constructor(
        private _matDialog: MatDialog,
        private _calendarService: MyTrainingCalendarService,
        public service: ModulesPoolService,
        private storage :StorageService,
    ) {
        // Set the defaults
        this.view = 'month';
        this.viewDate = new Date();
        this.activeDayIsOpen = true;
        this.selectedDay = { date: startOfDay(new Date()) };

        this.actions = [
            {
                label: '<i class="material-icons s-16">visibility</i>',
                onClick: ({ event }: { event: CalendarEvent }): void => {
                    console.log(event);
                },
            },
        ];
    }

    /**
     * Before View Renderer
     *
     * @param {any} header
     * @param {any} body
     */
    beforeMonthViewRender({ header, body }): void {
        /**
         * Get the selected day
         */
        const _selectedDay = body.find((_day) => {
            return _day.date.getTime() === this.selectedDay.date.getTime();
        });

        if (_selectedDay) {
            /**
             * Set selected day style
             * @type {string}
             */
            _selectedDay.cssClass = 'cal-selected';
        }
    }

    /**
     * Set events
     */
    setEvents(): void {
        const month = format(this.viewDate, 'MM').toString();
        const year = format(this.viewDate, 'YYYY');
        this.events = this._calendarService.getEvents(year, month).pipe(
            map((results) => {
                return results.map((event) => {
                    return new MyTrainingCalendar(event);
                });
            })
        );
    }

    ngOnInit(): void {
        const dt = new Date();
        this.setEvents();
    }

    /**
     * Day clicked
     *
     * @param {MonthViewDay} day
     */
    dayClicked(day: CalendarMonthViewDay): void {
        const date: Date = day.date;
        const events: CalendarEvent[] = day.events;

        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) &&
                    this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
        this.selectedDay = day;
    }

    /**
     * Event times changed
     * Event dropped or resized
     *
     * @param {CalendarEvent} event
     * @param {Date} newStart
     * @param {Date} newEnd
     */
    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        this.refresh.next(true);
    }

    getTimezoneOffsetString(date: Date): string {
        const timezoneOffset = date.getTimezoneOffset();
        const hoursOffset = String(
            Math.floor(Math.abs(timezoneOffset / 60))
        ).padStart(2, '0');
        const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(
            2,
            '0'
        );
        const direction = timezoneOffset > 0 ? '-' : '+';

        return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
    }

    fetchEvent(): void {
        const getStart: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay,
        }[this.view];
        console.log(format(getStart(this.viewDate), 'yyyy-MM-dd'));
    }

   
}
