import { Injectable } from "@angular/core";
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from "@angular/router";
import { Observable, of, EMPTY } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { ModulesPoolService } from "../modules-pool.service";

@Injectable({
    providedIn: "root"
})
export class TransactionResolverService implements Resolve<any> {
    constructor(private service: ModulesPoolService, private router: Router) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Observable<never> {
        let id = route.paramMap.get("id");

        return this.service.getDetail(id).pipe(
            take(1),
            mergeMap(data => {
                if (data) {
                    return of(data);
                } else {
                    // id not found
                    this.router.navigate(["modules-pool/view"]);
                    return EMPTY;
                }
            })
        );
    }
}
