import { Component, OnInit, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { Empmodulepool, Project } from '../model/empmodulepool';
import { EmpmodulepoolService } from '../empmodulepool.service';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { Profiles } from 'app/shared/auth/auth';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ModulespoolService } from 'app/main/modulespool/modulespool.service';
import { Modulespool } from 'app/main/modulespool/model/modulespool';
import { ModulesPoolPracticeTest } from 'app/main/modules-pool/model/modules-pool';
import { AppSettings } from 'app/app.settings';
import { CompetencyPool, SkillIndicatorPool } from 'app/shared/interfaces/references';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-empmodulepool',
  templateUrl: './form-empmodulepool.component.html',
  styleUrls: ['./form-empmodulepool.component.scss'],
  animations: fuseAnimations
})
export class FormEmpmodulepoolComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  isProject = false;
  isPractice = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  helper = new Helper();
  filteredModulesPool: Modulespool[] = [];
  filteredEmploye: Profiles[] = [];
  selectedModulesPool = new Modulespool;
  selectedProfile = new Profiles;
  practicalList: ModulesPoolPracticeTest[] = [];
  listEmployee: any[] = [];

  projectTitle = "";
  startDateProject = "";
  endDateProject = "";
  target = "";
  baseline = "";
  learningType: string;
  preTestId: string;
  postTestId: string;
  pre_duration: string;
  post_duration: string;
  projectItem: Project[] = [];
  listDataCertificate: any[] = [];
  private appSettings = new AppSettings();
  apiEndpoint: string;
  competencyPool: CompetencyPool[] = [];
  skillIndicatorPool: SkillIndicatorPool[] = [];

  progressUpload = 0;

  constructor(
    public dialogRef: MatDialogRef<FormEmpmodulepoolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Empmodulepool,
    private formBuilder: FormBuilder,
    private service: EmpmodulepoolService,
    private empModulePoolService: EmpmodulepoolService,
    private modulesPoolService: ModulespoolService
  ) {
    this.isEdit = this.data ? true : false;
  }

  ngOnInit() {
    this.loadDataCertificate();
    if (this.isEdit) {
      console.log(this.data);
      this.learningType = this.data.learning_type;
      this.selectedModulesPool.pool_name = this.data.module_pool_description;

      this.selectedProfile.nik = this.data.nik;
      this.selectedProfile.fullname = this.data.emp_name;

      let startDate = new Date(this.data.start_date);
      let endDate = new Date(this.data.end_date);

      this.form = this.formBuilder.group({
        id: new FormControl(this.data.id),
        nik: new FormControl(this.data.nik),
        startDate: new FormControl(startDate),
        endDate: new FormControl(endDate),
        el_module_pool_id: new FormControl(this.data.el_module_pool_id),
        modules_pool_desc: new FormControl(this.selectedModulesPool),
        employee: new FormControl(this.selectedProfile),
        startDatePractice: new FormControl(this.data.start_date_practice),
        endDatePractice: new FormControl(this.data.end_date_practice),
        certificate_configurations_id: new FormControl(''),
      });

      if (this.data.is_practice_required == '1') {
        this.isProject = true;
        // this.projectItem = this.data.project;
      }

    }
    else {
      this.form = this.formBuilder.group({
        nik: new FormControl('', Validators.required),
        startDate: new FormControl('', Validators.required),
        endDate: new FormControl('', Validators.required),
        startDatePractice: new FormControl(''),
        endDatePractice: new FormControl(''),
        modules_pool_desc: new FormControl(''),
        el_module_pool_id: new FormControl(''),
        employee: new FormControl(''),
        certificate_configurations_id: new FormControl(''),
      });
    }

    this.initAutoCompleteMp();
    this.initAutoCompleteEmp();

  }

  displayFnMp(data: Modulespool): string {

    if (data) {
      return data.pool_name;
    }
  }

  onSelectedMp(event: MatAutocompleteSelectedEvent): void {
    this.isProject = false;
    this.form.get('el_module_pool_id').setValue(event.option.value.id);

    this.practicalList = event.option.value.practical_test_list;
    if (event.option.value.is_project_required == '1') {
      this.isProject = true;
    }

    this.learningType = event.option.value.learning_type;
    this.competencyPool = event.option.value.competency_pool;
    this.skillIndicatorPool = event.option.value.skill_indicator_pool;
    this.preTestId = event.option.value.pre_test_id;
    this.postTestId = event.option.value.post_test_id;
    this.pre_duration = event.option.value.pre_duration;
    this.post_duration = event.option.value.post_duration;

    let employees = event.option.value.employee;

    for (let employee of employees) {
      this.listEmployee.push(
        new Profiles(employee.nik, employee.nama)
      );
    }

    this.form.get('nik').setValue(this.listEmployee);
  }

  initAutoCompleteMp(): void {
    this.form.get('modules_pool_desc')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.modulesPoolService.searchDataModulesPool(value).pipe(
            takeUntil(this.unsubscribeAll),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe(data => {
        this.filteredModulesPool = data;
      });
  }

  initAutoCompleteEmp(): void {
    this.form.get('employee')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.empModulePoolService.searchDataEmployee(value).pipe(
            takeUntil(this.unsubscribeAll),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe(users => {
        this.filteredEmploye = users;
      });
  }

  onSelectedEmp(event: MatAutocompleteSelectedEvent): void {
    this.listEmployee.push(
      new Profiles(event.option.value.nik, event.option.value.fullname, event.option.value.nik_atasan)
    );
    this.form.get('nik').setValue(this.listEmployee);
  }

  displayFnEmp(data: Profiles): string {
    if (data) {
      return data.nik + ' - ' + data.fullname;
    }
  }

  onSubmit(): void {
    const payload = {
      el_module_pool_id: this.form.value.el_module_pool_id,
      nik: this.form.value.nik,
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      project: this.projectItem,
      startDatePractice: this.form.value.startDatePractice,
      endDatePractice: this.form.value.endDatePractice,
      learningType: this.learningType,
      competencyPool: this.competencyPool,
      skillMatrixPool: this.skillIndicatorPool,
      preTestId: this.preTestId,
      postTestId: this.postTestId,
      pre_duration: this.pre_duration,
      post_duration: this.post_duration,
      certificate_configurations_id: this.form.value.certificate_configurations_id,
    };

    this.service.createData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  onUpdate(): void {
    const payload = {
      id: this.form.value.id,
      el_module_pool_id: this.form.value.el_module_pool_id,
      nik: this.form.value.nik,
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      project: this.projectItem,
      startDatePractice: this.form.value.startDatePractice,
      endDatePractice: this.form.value.endDatePractice,
      learningType: this.learningType,
      competencyPool: this.competencyPool,
      skillMatrixPool: this.skillIndicatorPool,
    }

    this.service.updateData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    })
  }

  onClickProjectSubmit(): void {

  }

  onRemoveProjectItem(idx: number): void {
    this.projectItem.splice(idx, 1);
  }

  onRemoveEmployee(idx: number): void {
    this.listEmployee.splice(idx, 1);
  }

  downloadTemplate(): void {
    this.apiEndpoint = this.appSettings.getApiEndpoint('empModulePool');
    window.open(this.apiEndpoint + '/templateUploadEmployee', '_blank');
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.apiEndpoint = this.appSettings.getApiEndpoint('files');

      const el = file;
      const formData = new FormData();
      // formData.append('id', 'test');
      formData.append('action', "importFile");
      formData.append('files', file, file.name);

      this.service.uploadFile(this.apiEndpoint, formData).subscribe(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.listEmployee.push({
            nik: res.data.data[i].nik,
            fullname: res.data.data[i].emp_name,
            start_date: res.data.data[i].start_date,
            end_date: res.data.data[i].end_date
          });
          this.selectedProfile.nik = res.data.data[i].nik;
          this.selectedProfile.fullname = res.data.data[i].emp_name;
          this.form.get('startDate').setValue(new Date(res.data.data[i].start_date));
          this.form.get('endDate').setValue(new Date(res.data.data[i].end_date));
        }


        this.form.get('employee').setValue(this.selectedProfile);
        this.form.get('nik').setValue(this.listEmployee);

      });

    }
  }

  loadDataCertificate() {
    this.service.loadDataCertificate().subscribe((response) => {
      this.listDataCertificate = response;
    });
  }



}
