import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardpoolComponent } from './dashboardpool.component';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabGroup, MatTabsModule, MatMenuModule, MatButtonToggleModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PracticaltestComponent } from './practicaltest/practicaltest.component';
import { PracticalTest } from '../empmodulepool/model/empmodulepool';
import { FuseWidgetModule } from '@fuse/components';
import { ProjectComponent } from './project/project.component';
import { RatingComponent } from './rating/rating.component';
import { StarRatingModule } from 'angular-star-rating';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CarouselModule } from 'ngx-owl-carousel-o';

const routes = [
  {
    path: 'dashboardpool',
    component: DashboardpoolComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DashboardpoolComponent, PracticaltestComponent, ProjectComponent, RatingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatMenuModule,
    FuseWidgetModule,
    MatButtonToggleModule,
    StarRatingModule,
    MatSidenavModule,
    MatListModule,
    CarouselModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    PracticaltestComponent,
    ProjectComponent,
    RatingComponent
  ]
})
export class DashboardpoolModule { }
