import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from 'app/shared/storage.service';
import { Observable, of } from 'rxjs';
import { Pagination, PaginationPage } from 'app/shared/pagination';
import { Modules } from './model/modules';
import { ApiResponse } from 'app/shared/api-response';
import { map, catchError } from 'rxjs/operators';
import { Modulestype } from '../modulestype/model/modulestype';
import {
    Dashboardpool,
    Dashboard,
    InvitationCode,
} from '../dashboardpool/model/dashboardpool';

@Injectable({
    providedIn: 'root',
})
export class ModulesService {
    private appSettings = new AppSettings();
    company: string;
    apiEndpoint: string;
    apiEndpointModulesPool: string;
    apiEndpointEmpModulesPool: string;
    apiLearningTest: string;

    constructor(private http: HttpClient, private storage: StorageService) {
        this.company = this.storage.get('empSite');
        this.apiEndpoint = this.appSettings.getApiEndpoint('learningModules');
        this.apiEndpointModulesPool = this.appSettings.getApiEndpoint(
            'modulesPool'
        );
        this.apiEndpointEmpModulesPool = this.appSettings.getApiEndpoint(
            'empModulePool'
        );
        this.apiLearningTest = this.appSettings.getApiEndpoint('learningTest');
    }

    loadData(page: PaginationPage): Observable<Pagination<Modules>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('action', 'modules');
        Object.keys(page).forEach((item) => {
            if (item === 'company') {
                httpParams = httpParams.append(item, this.company);
            } else {
                httpParams = httpParams.append(item, page[item]);
            }
        });

        return this.http.get<Pagination<Modules>>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    deleteData(params: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpoint, {
                action: 'deleteModules',
                data: params,
            })
            .pipe(
                map((result) => result),
                catchError((errors) => of(errors.error))
            );
    }

    createData(modulestype: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpoint, {
                action: 'createModules',
                data: modulestype,
            })
            .pipe(
                map((response) => response),
                catchError((error) => of(error.error))
            );
    }

    updateData(modulestype: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpoint, {
                action: 'updateModules',
                data: modulestype,
            })
            .pipe(
                map((response) => response),
                catchError((error) => of(error.error))
            );
    }

    getModulesType(): Observable<Modulestype[]> {
        return this.http.get<any[]>(this.apiEndpoint, {
            params: {
                action: 'getmodulestype',
                company: this.company,
                offset: '0',
                limit: '1000',
            },
        });
    }

    getModules(id: number): Observable<Modules> {
        const httpParams = new HttpParams()
            .set('action', 'viewData')
            .set('id', id.toString())
            .set('company', this.company)
            .set('offset', '0')
            .set('limit', '1');

        return this.http.get<Modules>(this.apiEndpointModulesPool, {
            params: httpParams,
        });
    }

    loadDataDashboard(
        page: PaginationPage,
        searchParam: Modules,
        id: any
    ): Observable<Pagination<Dashboard>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('action', 'modulesDashboard');
        httpParams = httpParams.append('module_pool_id', id.id);
        httpParams = httpParams.append('emp_module_pool_id', id.emp_id);
        Object.keys(page).forEach((item) => {
            if (item === 'company') {
                httpParams = httpParams.append(item, this.company);
            } else {
                httpParams = httpParams.append(item, page[item]);
            }
        });

        Object.keys(searchParam).forEach((item) => {
            httpParams = httpParams.append(item, searchParam[item]);
        });

        return this.http.get<Pagination<Dashboard>>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadDataDashboardPool(
        page: PaginationPage
    ): Observable<Pagination<Dashboardpool>> {
        let httpParams = new HttpParams();
        console.log(11);
        httpParams = httpParams.append('action', 'viewModulesPoolDashboard');
        Object.keys(page).forEach((item) => {
            if (item === 'company') {
                httpParams = httpParams.append(item, this.company);
            } else {
                httpParams = httpParams.append(item, page[item]);
            }
        });

        return this.http.get<Pagination<Dashboardpool>>(
            this.apiEndpointModulesPool,
            {
                params: httpParams,
            }
        );
    }

    getModulesDetail(
        id: number,
        empModulePoolId?: any,
        elModulePoolListId?: any
    ): Observable<Modules> {
        const httpParams = new HttpParams()
            .set('action', 'getmodules')
            .set('id', id.toString())
            .set('company', this.company)
            .set('emp_module_pool_id', empModulePoolId)
            .set('el_module_pool_list_id', elModulePoolListId)
            .set('offset', '0')
            .set('limit', '1');

        return this.http.get<Modules>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    submitModuleCheck(data: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpointEmpModulesPool, {
                action: 'submitModuleCheck',
                data: data,
            })
            .pipe(
                map((response) => response),
                catchError((error) => of(error.error))
            );
    }

    setSession(data: Dashboardpool, type: string): Observable<InvitationCode> {
        return this.http
            .post<ApiResponse>(this.apiLearningTest, {
                action: 'createSeasson',
                data: data,
                type: type,
            })
            .pipe(
                map((response) => response),
                catchError((error) => of(error.error))
            );
    }

    getFile(): Observable<Modules> {
        const httpParams = new HttpParams()
            .set('action', 'getFile')

        return this.http.get<Modules>(this.apiEndpointModulesPool, {
            params: httpParams,
        });
    }

    getTokens(): Observable<Modules> {
        const httpParams = new HttpParams()
            .set('action', 'getTokens')
        return this.http.get<Modules>(this.apiEndpointEmpModulesPool, {
            params: httpParams,
        });
    }

    retestPost(data: any): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiEndpointEmpModulesPool, {
            action: "retestPost",
            data: null,
            arr_data: data
        })
            .pipe(
                map(response => response),
                catchError(error => of(error.error))
            );
    }

}
