import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Observable, of } from 'rxjs';
import { AssesmentQuestionPool } from './assesment-question-pool';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssesmentQuestionPoolService {

private appSetting = new AppSettings();
public company: string;
public apiEndpoint: string;
public username: string;

  constructor(
    private http: HttpClient, 
    private storage: StorageService,
  ) { 
    this.company = this.storage.get('empSite');
    this.username = this.storage.get('username');
    this.apiEndpoint = this.appSetting.getApiEndpoint('modulesPool');
  }

  searchDataQuestionPool(search: string): Observable<any> {

    const httpParams = new HttpParams()
        .set('action', 'loadQuestionPool')
        .set('nik', this.username)
        .set('company', this.company)
        .set('search', search);
    return this.http
        .get<any>(this.apiEndpoint, {
            params: httpParams
        })
        .pipe(
            map(success => success),
            catchError(errors => of(errors.error))
        );
  }
}
