import {
    Component,
    OnInit,
    NgZone,
    AfterViewInit,
    OnDestroy,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { uniqueId } from 'lodash';
import { DashboardWidgetItem } from 'app/main/dashboard-admin/dashboard-admin';

am4core.useTheme(am4themes_animated);

@Component({
    selector: 'app-chart-training-count-per-day',
    templateUrl: './chart-training-count-per-day.component.html',
    styleUrls: ['./chart-training-count-per-day.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChartTrainingCountPerDayComponent
    implements OnInit, AfterViewInit, OnDestroy {
    chartId = uniqueId('chart_training_count_per_day');
    @Input('data') data: DashboardWidgetItem[];

    /**
     * Chart property
     */
    private chart: am4charts.XYChart;

    constructor(private zone: NgZone) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.initChart();
        });
    }

    initChart(): void {
        const chart = am4core.create(this.chartId, am4charts.XYChart);

        chart.padding(40, 40, 40, 40);
        chart.responsive.enabled = true;
        chart.exporting.menu = new am4core.ExportMenu();

        // const title = chart.titles.create();
        // title.text = this.data.company_id + " Headcount VS Cost";
        // title.fontSize = 14;
        // title.marginBottom = 10;
        // title.fill = am4core.color("#fff");

        /* Create axes */
        const categoryAxes = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxes.dataFields.category = 'month';
        categoryAxes.title.text = 'Months';
        // categoryAxes.title.fill = am4core.color('#FFFFFF');
        // categoryAxes.renderer.labels.template.fill = am4core.color('#FFFFFF');
        categoryAxes.renderer.minGridDistance = 20;

        const yAxes = chart.yAxes.push(new am4charts.ValueAxis());
        yAxes.title.text = 'Employee Training';
        // yAxes.renderer.labels.template.fill = am4core.color('#FFFFFF');
        // yAxes.title.fill = am4core.color('#FFFFFF');
        yAxes.renderer.grid.template.strokeOpacity = 0.2;
        yAxes.renderer.grid.template.stroke = am4core.color('#FFFFFF');
        yAxes.renderer.grid.template.strokeWidth = 1;

        const yAxesRight = chart.yAxes.push(new am4charts.ValueAxis());
        yAxesRight.title.text = 'Man Hours';
        yAxesRight.renderer.opposite = true;
        // yAxesRight.renderer.labels.template.fill = am4core.color('#FFFFFF');
        // yAxesRight.title.fill = am4core.color('#FFFFFF');

        const incommingSeries = chart.series.push(new am4charts.ColumnSeries());
        incommingSeries.dataFields.valueY = 'incomming';
        incommingSeries.dataFields.categoryX = 'month';
        incommingSeries.name = 'Incomming';
        incommingSeries.tooltipText = '{name}: [bold]{valueY}[/]';
        // incommingSeries.fill = am4core.color('#8BC34A');
        incommingSeries.fillOpacity = 0.5;
        // incommingSeries.stroke = am4core.color('#8BC34A');
        incommingSeries.showOnInit = false;

        const progressSeries = chart.series.push(new am4charts.ColumnSeries());
        progressSeries.dataFields.valueY = 'progress';
        progressSeries.dataFields.categoryX = 'month';
        progressSeries.name = 'Progress';
        progressSeries.tooltipText = '{name}: [bold]{valueY}[/]';
        // progressSeries.fill = am4core.color('#00BCD4');
        // progressSeries.fillOpacity = 0.5;
        // progressSeries.stroke = am4core.color('#00BCD4');
        progressSeries.showOnInit = false;

        const finishedSeries = chart.series.push(new am4charts.ColumnSeries());
        finishedSeries.dataFields.valueY = 'finished';
        finishedSeries.dataFields.categoryX = 'month';
        finishedSeries.name = 'Finished';
        finishedSeries.tooltipText = '{name}: [bold]{valueY}[/]';
        // finishedSeries.fill = am4core.color('#00BCD4');
        // finishedSeries.fillOpacity = 0.5;
        // finishedSeries.stroke = am4core.color('#00BCD4');
        finishedSeries.showOnInit = false;

        const manHoursAxes = chart.series.push(new am4charts.LineSeries());
        manHoursAxes.dataFields.valueY = 'usable_hours';
        manHoursAxes.dataFields.categoryX = 'month';
        manHoursAxes.name = 'Man hours';
        manHoursAxes.tooltipText = '{name}: [bold]{valueY}[/]';
        manHoursAxes.yAxis = yAxesRight;
        manHoursAxes.tensionX = 0.8;
        manHoursAxes.showOnInit = false;

        const costActualBullet = manHoursAxes.bullets.push(
            new am4charts.CircleBullet()
        );
        costActualBullet.circle.stroke = am4core.color('#fff');
        costActualBullet.circle.strokeWidth = 2;

        // Add legend
        chart.legend = new am4charts.Legend();
        // chart.legend.labels.template.fill = am4core.color('#fff');
        chart.legend.fontSize = 10;
        chart.legend.position = 'top';

        chart.data = this.data;
        chart.cursor = new am4charts.XYCursor();

        this.chart = chart;
    }

    ngOnDestroy(): void {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }
}
