import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Profiles } from 'app/shared/auth/auth';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Modulestype } from '../model/modulestype';
import { ModulestypeService } from '../modulestype.service';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';

@Component({
  selector: 'app-form-modulestype',
  templateUrl: './form-modulestype.component.html',
  styleUrls: ['./form-modulestype.component.scss']
})
export class FormModulestypeComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  filteredEmploye: Profiles[] = [];
  helper = new Helper();
  selectedEmployee: Profiles;

  constructor(
    public dialogRef: MatDialogRef<FormModulestypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modulestype,
    private formBuilder: FormBuilder,
    private service: ModulestypeService
  ) { 
    this.isEdit = this.data?true:false;
  }

  ngOnInit(): void{
    
    if (this.isEdit) {
      this.form = this.formBuilder.group({
        id : new FormControl(this.data.id, Validators.required),
        description : new FormControl(this.data.description, Validators.required)
      });
    } else {
      this.form = this.formBuilder.group({
        description : new FormControl('', Validators.required)
      });
    }
    
  }

  onSubmit(): void{
    const payload = {
      description : this.form.value.description
    };

    this.service.createData(payload).subscribe(response =>{
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  onUpdate(): void{
    const payload = {
      id : this.form.value.id,
      description : this.form.value.description
    }

    this.service.updateData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    })
  }

}
