import { Injectable } from '@angular/core';
import { FuseNavigation } from '@fuse/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  listMenu = new BehaviorSubject<FuseNavigation[]>(this.storageService.get('menus'))
  
  constructor( private storageService:StorageService ) { }
    
  getMenu(): Observable<FuseNavigation[]>{
    return of(this.storageService.get('menus'));
  }

  setMenu(): void {
    this.listMenu.next(JSON.parse(this.storageService.get('menus')));
  }
}
