import { Component, OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { ModulesPoolService } from '../modules-pool/modules-pool.service';
import { map, finalize } from 'rxjs/operators';
import { References } from 'app/shared/interfaces/references';
import { Observable, Subscription } from 'rxjs';
import {
    DashboardData,
    DashboardWidgetItem,
    ModulesPoolDashboard,
    ModulesPoolDashboardItem,
} from './dashboard-admin';
import { StorageService } from 'app/shared/storage.service';

@Component({
    selector: 'app-dashboard-admin',
    templateUrl: './dashboard-admin.component.html',
    styleUrls: ['./dashboard-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fuseAnimations],
})

export class DashboardAdminComponent implements OnInit, OnDestroy {
    form: FormGroup;
    chartData: DashboardWidgetItem[] = [];
    isLoading = false;
    listYear = ['2018','2019', '2020', '2021', '2022', '2023'];
    companies: Observable<References[]>;
    subAreas: Observable<References[]>;
    loadingState = {
        company: false,
        division: false,
        department: false,
    };
    unsubscribe: Subscription[] = [];
    data: Observable<DashboardData>;
    selectedPoolState = 'all';
    poolStates = ['all', 'incomming', 'progress', 'finished'];
    modulesPoolData: ModulesPoolDashboard = {
        all: [],
        finished: [],
        progress: [],
        incomming: [],
    };
    activePoolSelected: ModulesPoolDashboardItem[] = [];
    
    divisionList: References[] =[];
    departmentList: References[] =[];    
    filteredDivision: References[] =[];
    filteredDepartment: References[] =[];

    constructor(
        private modulesPoolService: ModulesPoolService,
        private formBuilder: FormBuilder,
        private storage: StorageService
    ) {
        this.form = this.formBuilder.group({
                        company: new FormControl(this.storage.get('empSite')),
                        division: new FormControl('all'),
                        department: new FormControl('all'),
                        year: new FormControl(new Date().getFullYear().toString()),
                    });

        /* const companySub = this.form.get('company')
            .valueChanges.subscribe((item) => {
                this.loadSubArea();
            });

        this.unsubscribe.push(companySub); */
    }
    
    ngOnInit(): void {
        this.loadCompany();
        this.getDivision();
        this.loadDashboardData();
    }

    getRandomInt(min, max): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getDate(inc: number): string {
        const dt = new Date();
        return (
            dt.getFullYear() +
            '-' +
            this.padNum(dt.getMonth()) +
            '-' +
            this.padNum(inc)
        );
    }

    padNum(n: number): string {
        return n > 9 ? '' + n : '0' + n;
    }

    onChangeDate(ev: MatDatepickerInputEvent<Date>): void {
        console.log(ev.value);
    }

    loadCompany(): void {
        this.loadingState.company = true;
        this.companies = this.modulesPoolService.loadCompany().pipe(
            map((result) => result),
            finalize(() => (this.loadingState.company = false))
        );
    }

    // loadSubArea(): void {
    //     this.loadingState.subArea = true;
    //     this.form.get('subArea').setValue('all');
    //     this.subAreas = this.modulesPoolService
    //         .loadSubArea(this.form.get('company').value)
    //         .pipe(
    //             map((result) => result),
    //             finalize(() => (this.loadingState.subArea = false))
    //         );
    // }

    loadDashboardData(): void {
        // this.isLoading = true;
        const company = this.form.get('company').value;
        const division = this.form.get('division').value;
        const department = this.form.get('department').value;
        const year = this.form.get('year').value;

        this.form.get('company').disable();
        this.form.get('division').disable();
        this.form.get('department').disable();
        this.form.get('year').disable();

        this.data = this.modulesPoolService
            .loadDashboardAdminData(year, company, division, department)
            .pipe(
                map((result) => {
                    this.modulesPoolData = result.modules_pool;
                    // this.setActivePool();
                    return result;
                }),
                finalize(() => {
                    this.isLoading = false;
                    this.form.get('company').enable();
                    this.form.get('division').enable();
                    this.form.get('department').enable();
                    this.form.get('year').enable();
                })
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe.map((val) => {
            val.unsubscribe();
        });
    }

    setAllPoolToActivePool(): void {
        this.activePoolSelected = [];
        Object.keys(this.modulesPoolData).forEach((key) => {
            this.modulesPoolData[key].map((val) => {
                this.activePoolSelected.push(val);
            });
        });
    }

    setActivePool(): void {
        this.activePoolSelected = [];
        this.modulesPoolData[this.selectedPoolState].map((val) => {
            this.activePoolSelected.push(val);
        });
    }

    getDivision(): void{
        this.loadingState.division = true;
        this.modulesPoolService.loadDivision(this.form.get('company').value).subscribe(response => {
            this.divisionList = response;
            this.filteredDivision = this.divisionList.slice();
            this.loadingState.division = false;
            
        });        
    }

    getDepartment(): void{
        this.loadingState.department = true;
        this.modulesPoolService.loadDepartment(this.form.get('division').value).subscribe(response => {
            this.departmentList = response;
            this.filteredDepartment = this.departmentList.slice();
            this.loadingState.department = false;
        });        
    }
}
