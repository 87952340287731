import { Injectable } from '@angular/core';
import { Subject, from, Observable } from 'rxjs';
import { User, Auth } from './auth';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage.service';
import { AppSettings } from 'app/app.settings';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = new Subject<User>();
  private setting = new AppSettings();
  private JWTHelper = new JwtHelperService();
  navigation: any;

  /**
     * Constructor
     *
     * @param {FuseNavigationService} _fuseNavigationService
     */

  constructor(
    private http:HttpClient,
    private storage:StorageService,
    private cookieService:CookieService,
    private _fuseNavigationService: FuseNavigationService,
    private _storage: StorageService
  ) { }
  
  public getDataLogin(tokenJwt:string):Observable<Auth>{
    return this.http.post<Auth>(
      this.setting.getApiEndpoint('auth')+'/generateNewToken',
      {
        token: tokenJwt,
        appID: 'elearning'
      }

    )
  }

  public setCookies(): any {
    return this.http
        .post(this.setting.getApiEndpoint('auth') + '/setCookie', null, {
            withCredentials: false
        })
        .subscribe(response => {
            this.cookieService.set('XSRF-TOKEN', response.toString());
        });
  }

  public setToken(token: string, menus:string): void {
    if (token) {
        const decodeToken = this.decodeToken(token);
        this.storage.set('token', token);
        this.storage.set('tokenID', decodeToken.tokenID);
        Object.keys(decodeToken.data).forEach(val => {
          if(val!='menus'){
            this.storage.set(val, decodeToken.data[val]);
          }
        });
    }

    if(menus && menus != ''){
      this.storage.set("menus", menus);
    }


  }

  public getToken(): string {
    return this.storage.get('token');
  }

  public decodeToken(token: string): any {
    const response = { data: null, tokenID: null };
    
    if (token) {
        const decoded = this.JWTHelper.decodeToken(token);
        response.data = decoded.data;
        response.tokenID = decoded.jti;
    }
    return response;
  }

  public logout(): void {
    // this.http.post<any>(
    //   this.setting.getApiEndpoint('auth') + '/logout',{}
    // );
    localStorage.clear();
  }

  public refreshToken(): Observable<any> {
    return this.http.post<any>(
        this.setting.getApiEndpoint('auth') + '/refreshToken1',
        {
            tokenID: this.storage.get('tokenID'),
            appID: 'elearning'
        }
    );
  }
  
  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) {
        return false;
    }
    return true;
  }

  public tokenWillExpire(): any {
    const date = moment();
    const token = this.getToken();
    let expireInMinutes = null;
    if (token) {
        const tokenWillExpire = moment(
            this.JWTHelper.getTokenExpirationDate(token)
        );
        expireInMinutes = tokenWillExpire.diff(date, 'minutes');
    }
    return expireInMinutes;
  }

  public isSessionExpired(): boolean {
    const token = this.getToken();
    return this.JWTHelper.isTokenExpired(token);
  }

  /**
   * // temporary
   * @param username string
   * @param password string
   * @returns void
   *
   */
  public login(username: string, password: string): Observable<any> {
    return this.http.post<any>(
      this.setting.getApiEndpoint('auth') + '/login',
      {
        username: username,
        password: password,
        appID: 'elearning'
      }
    );
  }
}
