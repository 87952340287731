import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { Banner } from '../banner/banner';
import { BannerService } from '../banner.service';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AssesmentQuestionPool } from 'app/shared/assesment-question-pool';
import { AssesmentQuestionPoolService } from 'app/shared/assesment-question-pool.service';

@Component({
  selector: 'app-form-banner',
  templateUrl: './form-banner.component.html',
  styleUrls: ['./form-banner.component.scss']
})
export class FormBannerComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  helper = new Helper();
  selectedEmployee: AssesmentQuestionPool;
  modulesType: any = [];
  filteredQuestionPool: AssesmentQuestionPool[] = [];

  constructor(
    public dialogRef: MatDialogRef<FormBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: BannerService,
    private assesmentQuestionPoolService: AssesmentQuestionPoolService
  ) {
    this.isEdit = this.data ? true : false;
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.form = this.formBuilder.group({
        id: new FormControl(this.data.id, Validators.required),
        banner_title: new FormControl(this.data.title, Validators.required),
        link_file: new FormControl('')
      });
    } else {
      this.form = this.formBuilder.group({
        banner_title: new FormControl('', Validators.required),
        link_file: new FormControl('')
      });
    }
  }

  onSubmit(): void {
    const payload = {
      banner_title: this.form.value.banner_title,
    };

    const formData = new FormData();
    const file: any = this.form.get('link_file').value;
    formData.append('file', this.form.get('link_file').value);
    formData.append('data', JSON.stringify(payload));

    this.service.createData(formData).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('link_file').setValue(file);
    }
  }

  onUpdate(): void {
    const payload = {
      id: this.form.value.id,
      banner_title: this.form.value.banner_title,
    }

    const formData = new FormData();
    const file: any = this.form.get('link_file').value;
    formData.append('file', this.form.get('link_file').value);
    formData.append('data', JSON.stringify(payload));

    this.service.updateData(formData).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    });
  }

  displayFn(questionPool: AssesmentQuestionPool): string {
    if (questionPool) {
      return questionPool.description;
    }
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.form.get('assesment_question_pool_id').setValue(event.option.value.id);
    this.form.get('assesment_question_pool').setValue(event.option.value.description);
  }

  initAutoComplete(): void {
    this.form.get('assesment_question_pool_desc')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.assesmentQuestionPoolService.searchDataQuestionPool(value).pipe(
            takeUntil(this.unsubscribeAll),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe(data => {
        this.filteredQuestionPool = data;
      });

  }

}
