export class Pagination<T> {
  aData: Array<T>;
  iTotalDisplayRecords: number;
  iTotalRecords: number;

  constructor(
    aData = new Array<T>(),
    iTotalDisplayRecords = 0,
    iTotalRecords = 0,
  ){
    this.aData = aData;
    this.iTotalDisplayRecords = iTotalDisplayRecords;
    this.iTotalRecords = iTotalRecords;
  }
}

export class PagingAttributes {
  iTotalDisplayRecords: number;
  iTotalRecords: number;

  constructor(iTotalDisplayRecords: number = 0, iTotalRecords: number = 0) {
      this.iTotalDisplayRecords = iTotalDisplayRecords;
      this.iTotalRecords = iTotalRecords;
  }
}

export class Page {
    limit = 20;
    offset = 0;
}

export class PaginationPage {
  limit = 20;
  offset = 0;
  search ? = '';
  company ? = '';
  subArea ? = '';
}
