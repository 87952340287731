import { DataSource } from '@angular/cdk/table';
import { Empmodulepool } from './empmodulepool';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PagingAttributes, PaginationPage, Pagination } from 'app/shared/pagination';
import { EmpmodulepoolService } from '../empmodulepool.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

export class EmpmodulepoolDs implements DataSource<Empmodulepool>{
  public pagination = new BehaviorSubject<PagingAttributes>(
    new PagingAttributes()
  );

  public subject = new BehaviorSubject<Empmodulepool[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private service: EmpmodulepoolService){}

  connect(collectionViewer: CollectionViewer):Observable<Empmodulepool[]>{
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer):void{
    this.subject.complete();
    this.loadingSubject.complete();
  }

  loadData(page:PaginationPage):void{
    this.loadingSubject.next(true);    
    this.service.loadData(page).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
    .subscribe((data:Pagination<Empmodulepool>)=>{
      this.subject.next(data.aData);
      this.pagination.next({
        iTotalDisplayRecords: data.iTotalDisplayRecords,
        iTotalRecords : data.iTotalRecords
      });
    });
  }
}
