import { NgModule } from '@angular/core';
import { DashboardAdminComponent } from './dashboard-admin.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import {
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTabsModule,
} from '@angular/material';
import { ChartTrainingCountPerDayModule } from 'app/shared/component/chart-training-count-per-day/chart-training-count-per-day.module';
import { ClusteredColumnChartModule } from 'app/shared/component/clustered-column-chart/clustered-column-chart.module';
import { MatSelectFilterModule } from 'mat-select-filter';

const routes: Routes = [
    {
        path: 'dashboard-admin',
        component: DashboardAdminComponent,
    },
];

@NgModule({
    declarations: [DashboardAdminComponent],
    imports: [
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatProgressBarModule,
        ChartTrainingCountPerDayModule,
        ClusteredColumnChartModule,
        MatSelectFilterModule,
        MatTabsModule
    ],
    exports: [RouterModule],
})
export class DashboardAdminModule {}
