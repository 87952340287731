import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModulesService } from '../modules/modules.service';
import { ModulessectionService } from './modulessection.service';
import { MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Subject, BehaviorSubject } from 'rxjs';
import { AccountsPage } from 'app/account/model/account';
import { ModulessectionDs } from './model/modulessection-ds';
import { SelectionModel } from '@angular/cdk/collections';
import { Modulessection } from './model/modulessection';
import { Helper } from 'app/shared/helper';
import { tap } from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { fuseAnimations } from '@fuse/animations';
import { CommonDialog, DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ApiResponse } from 'app/shared/api-response';
import { FormModulessectionComponent } from './form-modulessection/form-modulessection.component';
import { MediacontentComponent } from './mediacontent/mediacontent.component';

@Component({
  selector: 'app-modulessection',
  templateUrl: './modulessection.component.html',
  styleUrls: ['./modulessection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ModulessectionComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;

  /**
   * Constructor
   *
   * @param {FuseSidebarService} _fuseSidebarService
   */

  toggleDelete = false;
  searchTextSubject = new Subject<any>();
  page = new AccountsPage;
  dataSource: ModulessectionDs;
  selection = new SelectionModel<Modulessection>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  displayedColumns = ["no", "name", "type",  "header", "link","is_downloadable", "action"];
  helper = new Helper();
  id: number;
  module_title: string;
  description: string;
  module_type: string;
  timestamp: string;
  is_need_assesment: string;
  assesment_question_pool: string;
  assesment_parameter: string;
  assesment_duration: string;

  constructor(
    private route: ActivatedRoute,
    public service : ModulessectionService,
    public serviceModules : ModulesService,
    private splaceScreen : FuseSplashScreenService,
    public dialog : MatDialog,
    public commonDialogService : CommonDialogService,
    public snackBar : MatSnackBar,
    public router : Router
  ) { 
  }

  ngOnInit() {
    this.route.params.subscribe(observer => {
      this.id = observer.id;
    });
    
    this.loadDetail();
    this.dataSource = new ModulessectionDs(this.service);
    // this.splaceScreen.hide();
    this.dataSource.loadData(this.page, this.id);
    this.deleteToggle.subscribe(val => {
      if (val) {
        this.displayedColumns = ["select", ...this.displayedColumns];
      } else {
        const idx = this.displayedColumns.indexOf("select");
        if (idx !== -1) {
          this.displayedColumns.splice(idx, 1);
        }
      }
    });
    
  }

  ngAfterViewInit(): void{
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    this.searchTextSubject.subscribe(val => {
      setTimeout(() => console.log(val)
      , 1000);
    })
  }
  
  loadDetail(): void{
    this.serviceModules.getModulesDetail(this.id).subscribe(result =>{
      this.module_title= result.module_title;
      this.module_type= result.module_type;
      this.description= result.description;
      this.timestamp= result.timestamp;
      this.assesment_question_pool = result.assesment_question_pool;
      this.assesment_parameter = result.assesment_parameter;
      this.assesment_duration = result.assesment_duration;

      switch (result.is_need_assesment) {
        case "0":
          this.is_need_assesment = "False";
          break;
      
        default:
          this.is_need_assesment = "True";
          break;
      }
      
    });
  }

  loadData(): void{
    this.page.offset = this.paginator.pageIndex;
    this.page.limit = this.paginator.pageSize;
    this.dataSource.loadData(this.page, this.id);
    this.selection.clear();
  }

  openDialog(ref: Modulessection = null):void{
    const dialogRef = this.dialog.open(FormModulessectionComponent,{
      data: {"ref": ref, "id":this.id},
      panelClass: 'elearning-dialog-half'
    })

    dialogRef
      .afterClosed()
        .subscribe((result:DialogEvent<ApiResponse>) => {
          if (result instanceof DialogEvent) {
            if (result.isSubmit()) {
              this.responseDialog(result.getData());
            }
          } else {
            this.loadData();
          }
          
        });
  }

  deleteAction():void{
    const dialog = new CommonDialog({
      title: "Delete Modules Type",
      message: "Are you sure want to delete this Type ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        const payload = this.helper.buildDeletePayload(
          this.selection.selected
        );
        this.service
          .deleteData(payload)
          .subscribe(response => 
            this.responseDialog(new ApiResponse(response))
            );
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })
    
  }

  search(query: string):void{
    this.page.search = query;
    this.dataSource.loadData(this.page, this.id);
  }
  
  masterToggle():void{
    this.isAllSelected()
    ? this.selection.clear() : this.dataSource.subject.value.forEach(
      row => this.selection.select(row)
    );
  }

  isAllSelected():boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.pagination.value.iTotalDisplayRecords;
    console.log(numSelected, numRows);
    
    return numSelected === numRows;
  }
  
  responseDialog(response: ApiResponse): void {
    const dialog = new CommonDialog({
        title: response.title,
        message: response.message
    });
    this.commonDialogService.open(dialog).subscribe(() => this.loadData());
  }

  backToView(): void {
    this.router.navigate(["modules"]);
  }

  viewContent(ref: Modulessection): void{
    const dialogRef = this.dialog.open(MediacontentComponent,{
      data: {"ref": ref},
      panelClass: 'elearning-dialog-half'
    })

    dialogRef
      .afterClosed()
        .subscribe((result:DialogEvent<ApiResponse>) => {
          if (result instanceof DialogEvent) {
            if (result.isSubmit()) {
              this.responseDialog(result.getData());
            }
          } else {
            this.loadData();
          }
          
        });
  }
  
}
