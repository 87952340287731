import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpmodulepoolComponent } from './empmodulepool.component';
import { FormEmpmodulepoolComponent } from './form-empmodulepool/form-empmodulepool.component';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabsModule, MatRadioModule, MatDatepickerModule, MatDividerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DetailEmpmodulepoolComponent } from './detail-empmodulepool/detail-empmodulepool.component';
import { FuseWidgetModule } from '@fuse/components';
import { UpdatePracticeScoreComponent } from './detail-empmodulepool/update-practice-score/update-practice-score.component';
import { CompetenciesComponent } from './detail-empmodulepool/competencies/competencies.component';
import { ProjectDetailComponent } from './detail-empmodulepool/project-detail/project-detail.component';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ProjectComponent } from './detail-empmodulepool/project/project.component';
import { SkillMatrixComponent } from './detail-empmodulepool/skill-matrix/skill-matrix.component';
import { MatMenuModule } from '@angular/material/menu';


const routes = [
  {
    path  : 'empmodulepool',
    component : EmpmodulepoolComponent,
    canActivate : [AuthGuard]
  },
  {
    path  : 'detailempmodulepool/:id',
    component : DetailEmpmodulepoolComponent,
    canActivate : [AuthGuard]
  }
];

@NgModule({
  declarations: [EmpmodulepoolComponent, FormEmpmodulepoolComponent, DetailEmpmodulepoolComponent, UpdatePracticeScoreComponent, CompetenciesComponent, ProjectDetailComponent, CreateCertificateComponent,ProjectComponent, SkillMatrixComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTabsModule,
    FuseWidgetModule,
    MatDividerModule,
    MatSelectFilterModule,
    MatMenuModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents:[
    FormEmpmodulepoolComponent,
    DetailEmpmodulepoolComponent,
    UpdatePracticeScoreComponent,
    ProjectDetailComponent,
    CreateCertificateComponent,
    ProjectComponent,
    SkillMatrixComponent
  ]
})
export class EmpmodulepoolModule { }
