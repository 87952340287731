import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';
import { TrainingCalendar } from '../training-calendar.model';
import * as moment from 'moment';

@Component({
    selector: 'app-event-form',
    templateUrl: './event-form.component.html',
    styleUrls: ['./event-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EventFormComponent implements OnInit {
    event: TrainingCalendar[];

    constructor(
        public matDialogRef: MatDialogRef<EventFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.event = _data.events;
    }

    ngOnInit(): void {
        console.log(this.event);
    }

    formatDate(dt: Date): string {
        return moment(dt).format('DD MMM YYYY');
    }
}
