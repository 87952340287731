import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClientXsrfModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { MatDividerModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule } from '@angular/material';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
// import { SampleModule } from 'app/main/sample/sample.module';
import { AuthService } from './shared/auth/auth.service';
import { AuthenticationModule } from './main/authentication/authentication.module';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { CommonDialogModule } from './shared/component/dialog/common-dialog/common-dialog.module';
import { MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { ModulestypeModule } from './main/modulestype/modulestype.module';
import { ModulesModule } from './main/modules/modules.module';
import { BannerModule } from './main/banner/banner.module';
import { ModulessectionModule } from './main/modulessection/modulessection.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { DashboardpoolModule } from './main/dashboardpool/dashboardpool.module';
import { EmpmodulepoolModule } from './main/empmodulepool/empmodulepool.module';
import { ModulesPoolModule } from './main/modules-pool/modules-pool.module';
import { LoginComponent } from './main/login/login.component';
import { Error404Component } from './shared/component/error404/error404.component';
import { LoginModule } from './main/login/login.module';
import { TrainingCalendarModule } from './main/training-calendar/training-calendar.module';
import { DashboardAdminModule } from './main/dashboard-admin/dashboard-admin.module';
import { MyTrainingCalendarModule } from './main/my-training-calendar/my-training-calendar.module';
import { MyTrainingModule } from './main/my-training/my-training.module';
import { DialogTrainingDetailComponent } from './layout/components/quick-panel/dialog-training-detail/dialog-training-detail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: Error404Component,
    },
];

export function tokenGetter(): any {
    return localStorage.getItem('token');
}

@NgModule({
    declarations: [AppComponent, Error404Component, DialogTrainingDetailComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        ReactiveFormsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: [
                    'localhost',
                    'hcportal.nabatisnack.co.id',
                    'nabatisnack.co.id',
                    '10.1.40.92',
                    '10.1.40.202',
                ],
            },
        }),
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        // SampleModule,
        AuthenticationModule,
        CommonDialogModule,
        ModulestypeModule,
        ModulesModule,
        ModulessectionModule,
        BannerModule,
        DashboardModule,
        DashboardpoolModule,
        EmpmodulepoolModule,
        MyTrainingModule,
        ModulesPoolModule,
        LoginModule,
        TrainingCalendarModule,
        DashboardAdminModule,
        MyTrainingCalendarModule,
        MatDividerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatProgressBarModule,
        MatFormFieldModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    entryComponents: [
        DialogTrainingDetailComponent
    ]
})
export class AppModule { }
