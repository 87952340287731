import { PaginationPage, PagingAttributes, Pagination } from 'app/shared/pagination';
import { Modules } from 'app/main/modules/model/modules';
import { DataSource } from '@angular/cdk/table';
import { Dashboard } from 'app/main/dashboardpool/model/dashboardpool';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ModulesService } from 'app/main/modules/modules.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

export class DashboardDs implements DataSource<Dashboard> {
  public pagination = new BehaviorSubject<PagingAttributes>(
    new PagingAttributes()
  );

  public subject = new BehaviorSubject<Dashboard[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private service: ModulesService,
    public router: Router
    ) {}

  connect(collectionViewer: CollectionViewer): Observable<Dashboard[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
      this.subject.complete();
      this.loadingSubject.complete();
  }

  loadData(page:PaginationPage, searchParam:Modules, id:any):void{
    this.loadingSubject.next(true);    
    this.service.loadDataDashboard(page, searchParam, id).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
    .subscribe((data:Pagination<Dashboard>)=>{
      this.subject.next(data.aData);
      var i = data.aData.map(function(e) { return e.status_check; }).indexOf('0');
      if(i == -1){
        var j = data.aData.map(function(e) { return e.status_check; }).indexOf(null);
        if(j == -1){
          const url = `dashboardpool`;
          this.router.navigate([url]);
        }
      }

      
      this.pagination.next({
        iTotalDisplayRecords: data.iTotalDisplayRecords,
        iTotalRecords : data.iTotalRecords
      });
    });
  }
}
