import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from 'app/app.settings';
import { Observable } from 'rxjs';
import { MyTrainingCalendar } from './my-training-calendar.model';

@Injectable()
export class MyTrainingCalendarService {
    private appSettings = new AppSettings();
    apiEndpoint: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get events
     *
     * @returns {Observable<any>}
     */
    getEvents(year: string, month: string): Observable<MyTrainingCalendar[]> {
        const params = new HttpParams()
            .set('action', 'getMyCalendarData')
            .set('year', year)
            .set('month', month);

        return this._httpClient.get<MyTrainingCalendar[]>(this.apiEndpoint, {
            params: params,
        });
    }
}
