import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { ModulesDs } from './model/modules-ds';
import { Modules } from './model/modules';
import { SelectionModel } from '@angular/cdk/collections';
import { Helper } from 'app/shared/helper';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { ModulesService } from './modules.service';
import { tap } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ApiResponse } from 'app/shared/api-response';
import { CommonDialog, DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { FormModulesComponent } from './form-modules/form-modules.component';
import { Router } from '@angular/router';
import { PaginationPage } from 'app/shared/pagination';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { StorageService } from 'app/shared/storage.service';
import { ModulesPoolService } from "app/main/modules-pool/modules-pool.service";
import { References } from 'app/shared/interfaces/references';
import { takeUntil, map, finalize } from "rxjs/operators";

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
  animations: fuseAnimations
})
export class ModulesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  form: FormGroup;
  toggleDelete = false;
  searchTextSubject = new Subject<any>();
  page = new PaginationPage;
  dataSource: ModulesDs;
  selection = new SelectionModel<Modules>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  displayedColumns = ["no", "module title", "description", "module type", "action"];
  // displayedColumns = ["no", "module title", "description", "module type", "is need assesment", "question pool", "assesment parameter", "assesment duration", "action"];
  helper = new Helper();

  toggleFilter = false;

  filter = {
    title: null,
    description: null,
    module_type: null,
    is_need_assesment: null,
    assesment_question_pool: null,
    assesment_parameter: null,
    assesment_duration: null,
    search: null,
    company: this.storage.get('empSite')
  }

  loadingState = {
    company: false,
  };
  companies: Observable<References[]>;

  constructor(
    private splaceScreen: FuseSplashScreenService,
    public dialog: MatDialog,
    public commonDialogService: CommonDialogService,
    public snackBar: MatSnackBar,
    public service: ModulesService,
    public router: Router,
    private formBuilder: FormBuilder,
    public serviceModulesPool: ModulesPoolService,
    private storage: StorageService

  ) {
  }

  ngOnInit() {
    this.loadCompany();
    this.dataSource = new ModulesDs(this.service);
    // this.splaceScreen.hide();
    this.dataSource.loadData(this.page);
    this.deleteToggle.subscribe(val => {
      if (val) {
        this.displayedColumns = ["select", ...this.displayedColumns];
      } else {
        const idx = this.displayedColumns.indexOf("select");
        if (idx !== -1) {
          this.displayedColumns.splice(idx, 1);
        }
      }
    });

    this.form = this.formBuilder.group({
      title: new FormControl(null),
      description: new FormControl(null),
      module_type: new FormControl(null),
      is_need_assesment: new FormControl(null),
      assesment_question_pool: new FormControl(null),
      assesment_parameter: new FormControl(null),
      assesment_duration: new FormControl(null),
      search: new FormControl(null),
      company: new FormControl(this.storage.get('empSite')),
    });
  }

  loadData(): void {
    this.page.offset = this.paginator.pageIndex;
    this.page.limit = this.paginator.pageSize;
    this.dataSource.loadData(this.page);
    this.selection.clear();
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    this.searchTextSubject.subscribe(val => {
      setTimeout(() => console.log(val)
        , 1000);
    })
  }

  search(query: string): void {
    this.filter.search = query;
    this.page.search = JSON.stringify(this.filter);
    this.dataSource.loadData(this.page);
  }

  searchFilter(): void {
    this.filter = {
      title: this.form.value.title,
      description: this.form.value.description,
      module_type: this.form.value.module_type,
      is_need_assesment: this.form.value.is_need_assesment,
      assesment_question_pool: this.form.value.assesment_question_pool,
      assesment_parameter: this.form.value.assesment_parameter,
      assesment_duration: this.form.value.assesment_duration,
      search: this.form.value.search,
      company: this.form.value.company,
    }
    this.page.search = JSON.stringify(this.filter);
    this.dataSource.loadData(this.page);
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear() : this.dataSource.subject.value.forEach(
        row => this.selection.select(row)
      );
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.pagination.value.iTotalDisplayRecords;
    return numSelected === numRows;
  }

  openDialog(ref: Modules = null): void {
    const dialogRef = this.dialog.open(FormModulesComponent, {
      data: ref,
      panelClass: 'elearning-dialog'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  responseDialog(response: ApiResponse): void {
    const dialog = new CommonDialog({
      title: response.title,
      message: response.message
    });
    this.commonDialogService.open(dialog).subscribe(() => this.loadData());
  }

  deleteAction(): void {
    const dialog = new CommonDialog({
      title: "Delete Modules Type",
      message: "Are you sure want to delete this Type ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        const payload = this.helper.buildDeletePayload(
          this.selection.selected
        );
        this.service
          .deleteData(payload)
          .subscribe(response =>
            this.responseDialog(new ApiResponse(response))
          );
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }

  detail(param: any): void {
    let id: string = param.id;
    this.router.navigate([`section/${id}`]);
  }

  loadCompany(): void {
    this.loadingState.company = true;
    this.companies = this.serviceModulesPool.loadCompany().pipe(
      map((result) => result),
      finalize(() => (this.loadingState.company = false))
    );
  }
}
