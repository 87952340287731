import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Profiles } from 'app/shared/auth/auth';
import { Helper } from 'app/shared/helper';
import { Modulessection } from '../model/modulessection';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModulessectionService } from '../modulessection.service';
import { fuseAnimations } from '@fuse/animations';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';

declare var tinymce: any;
@Component({
  selector: 'app-form-modulessection',
  templateUrl: './form-modulessection.component.html',
  styleUrls: ['./form-modulessection.component.scss'],
  animations: fuseAnimations
})

export class FormModulessectionComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  isEdit = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  filteredEmploye: Profiles[] = [];
  helper = new Helper();
  selectedEmployee: Profiles;
  sectionType:string[] = ['audio', 'content', 'e-book', 'video', 'picture', 'embed'];
  idModules:string;
  data_:Modulessection;

  constructor(
    public dialogRef: MatDialogRef<FormModulessectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: ModulessectionService
  ) { 
    this.idModules = this.data.id;
    this.data_ = this.data.ref; 
    this.isEdit = this.data_?true:false;
  }

  ngOnInit() {   
       
    if (this.isEdit) {
      console.log('1');
      this.form = this.formBuilder.group({
        id : new FormControl(this.data_.id),
        description : new FormControl(this.data_.description),
        section_name : new FormControl(this.data_.section_name),
        section_type : new FormControl(this.data_.section_type),
        section_header : new FormControl(this.data_.section_header),
        content : new FormControl(this.data_.content),
        is_downloadable : new FormControl(this.data_.is_downloadable),
        link_file : new FormControl('')
      });
    } else {
      this.form = this.formBuilder.group({
        description : new FormControl('', Validators.required),
        section_name : new FormControl('', Validators.required),
        section_type : new FormControl('', Validators.required),
        section_header : new FormControl('', Validators.required),
        content : new FormControl(''),
        is_downloadable : new FormControl('0'),
        link_file : new FormControl('')
      });
    }
  }

  ngAfterViewInit(){

  }

  onSubmit(): void{    
    const payload = {
      description : this.form.value.description,
      section_name : this.form.value.section_name,
      section_type : this.form.value.section_type,
      section_header : this.form.value.section_header,
      content : this.form.value.content,
      is_downloadable : this.form.value.is_downloadable,
      el_modules_id : this.idModules
    };

    const formData = new FormData();
    const file:any = this.form.get('link_file').value;
    formData.append('file', this.form.get('link_file').value);
    formData.append('data', JSON.stringify(payload));

    this.service.createData(formData).subscribe(response =>{
      this.dialogRef.close(new DialogEvent('submit', response));
    });
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('link_file').setValue(file);
    }
  }

  onUpdate(): void{    
    const payload = {
      description : this.form.value.description,
      section_name : this.form.value.section_name,
      section_type : this.form.value.section_type,
      section_header : this.form.value.section_header,
      content : this.form.value.content,
      is_downloadable : this.form.value.is_downloadable,
      el_modules_id : this.idModules,
      link_file: this.data_.link_file,
      id : this.form.value.id,
    };

    const formData = new FormData();
    const file:any = this.form.get('link_file').value;
    formData.append('file', this.form.get('link_file').value);
    formData.append('data', JSON.stringify(payload));

    this.service.updateData(formData).subscribe(response =>{
      this.dialogRef.close(new DialogEvent('submit', response));
    });
  }

  openFileManager(): void{
    window.open("https://hcportal.nabatisnack.co.id:8087/elearning_rev/file-manager", "MsgWindow", "width=900,height=600");
  }

}
