import { DataSource } from "@angular/cdk/table";
import { Dashboardpool} from "./dashboardpool";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
    PagingAttributes,
    PaginationPage,
    Pagination
} from "app/shared/pagination";
import { ModulesService } from "app/main/modules/modules.service";
import { CollectionViewer } from "@angular/cdk/collections";
import { catchError, finalize } from "rxjs/operators";
import { Modules } from "app/main/modules/model/modules";

export class DashboardpoolDs implements DataSource<Dashboardpool> {
    public pagination = new BehaviorSubject<PagingAttributes>(
        new PagingAttributes()
    );

    public subject = new BehaviorSubject<Dashboardpool[]>([]);
    public loadingSubject = new BehaviorSubject<boolean>(false);

    constructor(private service: ModulesService) {}

    connect(collectionViewer: CollectionViewer): Observable<Dashboardpool[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.loadingSubject.complete();
    }

    loadDataDashboarPool(page:PaginationPage):void{
      this.loadingSubject.next(true);    
      this.service.loadDataDashboardPool(page).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((data:Pagination<Dashboardpool>)=>{
        this.subject.next(data.aData);
        this.pagination.next({
          iTotalDisplayRecords: data.iTotalDisplayRecords,
          iTotalRecords : data.iTotalRecords
        });
      });
    }
}
