import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    Inject,
    OnDestroy
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { MatPaginator } from "@angular/material";
import { Subject, BehaviorSubject, pipe, Observable, Subscription } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { Helper } from "app/shared/helper";
import { Router } from "@angular/router";
import { tap, takeUntil} from "rxjs/operators";
import { ModulesPoolPage, ModulesPool } from "../model/modules-pool";
import { ModulespoolDs } from "../model/modules-pool-ds";
import { ModulesPoolService } from "../modules-pool.service";
import { ModulesPoolComponent } from "../modules-pool.component";
import * as _ from "lodash";
import { CommonDialogService } from "app/shared/component/dialog/common-dialog/common-dialog.service";
import { ApiResponse } from "app/shared/api-response";
import { CommonDialog } from "app/shared/component/dialog/common-dialog/common-dialog";
import { References } from 'app/shared/interfaces/references';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: "app-view",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.scss"],
    animations: [fuseAnimations]
})
export class ViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    form: FormGroup;
    unsubscribeAll = new Subject();
    toggleDelete = false;
    page = new ModulesPoolPage();
    dataSource: ModulespoolDs;
    

    selection = new SelectionModel<ModulesPool>(true, []);
    deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
    displayedColumns = [
        "bu",
        "sub_area",
        "pool_name",
        "pool_type",
        "learning_type",    
        // "learning_duration",    
        "pre_test_name",
        "post_test_name",
        "project_required",
        "is_restrict",
        "action"
    ];
    helper = new Helper();

    loadingState = {
        company: false,
        subArea: false,
    };

    unsubscribe: Subscription[] = [];
    
    constructor(
        private service: ModulesPoolService,
        private commonDialogService: CommonDialogService,
        @Inject(ModulesPoolComponent) private parent: ModulesPoolComponent
    ) {
        this.parent.searchTextSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(text => this.search(text));

        this.parent.filterSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(param => this.loadDataFilter(param));


        this.parent.deleteToggle
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(val => this.deleteToggle.next(val));

        /**
         * TODO
         *
         * Get translation
         */
        this.parent.titleSubject.next("View Module Pool");
        this.parent.descriptionSubject.next("View Module Pool");

        /**
         * Subscribe for delete action
         */
        this.parent.deleteAction
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(val => {
                if (val) {
                    this.deleteData();
                }
            });
    }

    ngOnInit() {
        this.dataSource = new ModulespoolDs(this.service);
        this.dataSource.loadData(this.page);
        this.deleteToggle.subscribe(val => {
            this.toggleDelete = val;
            if (val) {
                this.displayedColumns = ["select", ...this.displayedColumns];
            } else {
                const idx = this.displayedColumns.indexOf("select");
                if (idx !== -1) {
                    this.displayedColumns.splice(idx, 1);
                }
            }
        });
    }

    loadData(): void {
        this.page.offset = this.paginator.pageIndex;
        this.page.limit = this.paginator.pageSize;
        this.dataSource.loadData(this.page);
        this.selection.clear();
    }

    ngAfterViewInit(): void {
        this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    }

    search(query: string): void {
        this.page.search = query;
        this.dataSource.loadData(this.page);
    }


    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.subject.value.forEach(row =>
                  this.selection.select(row)
              );
    }

    deleteData(): void {
        if (this.selection.isEmpty()) {
            return;
        }

        let temp = _.cloneDeep(this.selection.selected);
        const payload = temp.map(val => {
            return val.id;
        });
        const deleteData = this.service.deleteData(payload);
        this.parent.blockComponent.next(true);
        deleteData.subscribe(
            response => {
                this.parent.blockComponent.next(false);
                this.parent.deleteToggle.next(false);
                this.responseDialog(response);
            },
            error => {
                this.parent.blockComponent.next(false);
                this.responseDialog(error);
            }
        );
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.pagination.value.iTotalDisplayRecords;
        return numSelected === numRows;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    responseDialog(response: ApiResponse): void {
        const dialog = new CommonDialog({
            title: response.title,
            message: response.message
        });
        this.commonDialogService
            .open(dialog)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(response => {
                if (response.isSubmit()) {
                    this.loadData();
                }
            });
    }

    toUpdate(ref: any): void {
        this.parent.toUpdateTransaction(ref);
    }

    loadDataFilter(param:any[]): void {
        this.page.company = param[0].company;
        this.page.subArea = param[0].subArea;
        this.dataSource.loadData(this.page);
    }

}
